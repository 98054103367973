import { useEffect, useState } from "react";

import { Modal, Button, Form, Dropdown } from "react-bootstrap";

import { CustomButton } from "src/shared/components";
import { useRootStore } from "src/shared/stores/initStore";
import style from "src/shared/ui/modals/paymentForms/index.module.css";

export default function PaymentUpdateForm(props) {
  const { show, onHide, initialTitle, onUpdate, initialType, formId } = props;
  const { paymentFormsStore } = useRootStore();
  const [title, setTitle] = useState(initialTitle);
  const [selectedType, setSelectedType] = useState(initialType);

  useEffect(() => {
    if (show) {
      paymentFormsStore.clearError();
      paymentFormsStore.loadActTypes();
    }
  }, [show, paymentFormsStore]);

  const handleSave = () => {
    const sanitizedTitle = typeof title === "string" ? title.trim() : "";
    onUpdate(formId, { title: sanitizedTitle, act_type: selectedType });
  };

  useEffect(() => {
    setTitle(initialTitle);
    setSelectedType(initialType);
  }, [initialTitle, initialType]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={style.modalContainer}
    >
      <Modal.Header closeButton>
        <Modal.Title>Изменение формы выплаты</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="paymentFormTitle">
            <Form.Label>Название формы выплаты</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите новое название"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                paymentFormsStore.clearError();
              }}
              isInvalid={!!paymentFormsStore.error}
            />
            <Form.Control.Feedback type="invalid">
              {paymentFormsStore.error}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formPaymentType" className={style.mt}>
            <Form.Label>Тип алгоритма формирования ведомостей</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className={style.dropdownContainer}
              >
                {selectedType || "Выберите тип алгоритма"}
              </Dropdown.Toggle>

              <Dropdown.Menu className={style.scrollableDropdownMenu}>
                {paymentFormsStore.actTypes.map((type) => (
                  <Dropdown.Item
                    key={type}
                    onClick={() => setSelectedType(type)}
                  >
                    {type}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setSelectedType(null)}>
                  Сбросить выбор
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={style.footer}>
        <Button
          variant="secondary"
          onClick={onHide}
          className={style.cancelBtn}
        >
          Отмена
        </Button>
        <CustomButton onClick={handleSave}>Сохранить</CustomButton>
      </Modal.Footer>
    </Modal>
  );
}
