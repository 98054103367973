export const thVacationsArray = [
  "ФИО",
  "Никнейм",
  "Отдел",
  "Дата начала отпуска",
  "Дата конца отпуска",
  "Дней в отпуске",
  "Тип отпуска",
  " ",
];
