import { useEffect, useState } from "react";

import "src/App/Styles/global-centering.css";
import { icLogoMini } from "src/assets/icons/icons";
import styles from "src/pages/Home/index.module.css";
import { getUsersMe } from "src/shared/api/auth";
import Spinner from "src/shared/components/loaders/Spinner";

const requestUserData = async (setUserData) => {
  try {
    const res = await getUsersMe();
    setUserData(res.data);
  } catch (error) {
    console.error("О Ш И Б К А", error);
  }
};
export default function Home() {
  const [userData, setUserData] = useState(null);
  const roles = {
    accountant: "Бухгалтер",
    department_head: "Руководитель отдела",
  };

  useEffect(() => {
    requestUserData(setUserData);
  }, []);

  useEffect(() => {
    document.title = "Главная - Physical";
  }, []);

  return (
    <div className={styles.container}>
      {userData ? (
        <div className={styles.containerGreeting}>
          <img className={styles.logo} src={icLogoMini} alt="logo" />
          <h4 className={styles.name} >{`Добро пожаловать, ${userData.profile.full_name}`}</h4>
          <h4 className={styles.role} >{`Ваша роль: ${roles[userData.profile.role]}`}</h4>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
