import { observer } from "mobx-react";
import { Dropdown } from "react-bootstrap";

import { useRootStore } from "src/shared/stores/initStore";

import style from "./DepartmentFilterDropdown.module.css";

const DepartmentFilterDropdown = observer(
  ({ selectedDepartments, onToggleDepartment, onResetDepartments }) => {
    const { departamentStore } = useRootStore();

    return (
      <Dropdown autoClose="outside">
        <Dropdown.Toggle className={style.dropdownContainer}>
          {selectedDepartments.length === 0
            ? "Выберите отделы"
            : `Выбрано отделов: ${selectedDepartments.length}`}
        </Dropdown.Toggle>
        <Dropdown.Menu className={style.scrollableDropdownMenu}>
          {departamentStore.departaments.map((department) => (
            <Dropdown.Item
              key={department.id}
              onClick={() => onToggleDepartment(department.id)}
              active={selectedDepartments.includes(department.id)}
            >
              {department.title}
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
          <Dropdown.Item onClick={onResetDepartments}>
            Сбросить фильтры
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  },
);

export default DepartmentFilterDropdown;
