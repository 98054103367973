/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext } from "react";

import { useLocalObservable } from "mobx-react";
import PropTypes from "prop-types";

import { AuthStore } from "src/shared/stores/AuthStore";
import { AllAccrualsStore } from "src/shared/stores/allAccruals";
import { AccrualsBetsStore } from "src/shared/stores/bets";
import { AccrualsDealsFormStore } from "src/shared/stores/deals";
import { DepartamentStore } from "src/shared/stores/dpartament";
import { EmployeesStore } from "src/shared/stores/employees";
import { EmploymentFormStore } from "src/shared/stores/employmentForms";
import { PaymentFormsStore } from "src/shared/stores/paymentForms";
import { ReportsStore } from "src/shared/stores/reports";
import { SidebarStore } from "src/shared/stores/sidebar";
import { UsersStore } from "src/shared/stores/users";

import { ExtraPaymentsStore } from "./extraPaymentsStore";
import { VacationsStore } from "./vacations";

export const StoreContext = createContext({});

export function StoreProvider({ children }) {
  const authStore = useLocalObservable(AuthStore);
  const paymentFormsStore = useLocalObservable(PaymentFormsStore);
  const usersStore = useLocalObservable(UsersStore);
  const employmentFormsStore = useLocalObservable(EmploymentFormStore);
  const sidebarStore = useLocalObservable(SidebarStore);
  const employeesStore = useLocalObservable(EmployeesStore);
  const departamentStore = useLocalObservable(DepartamentStore);
  const extraPaymentsStore = useLocalObservable(ExtraPaymentsStore);
  const accrualsDealsStore = useLocalObservable(AccrualsDealsFormStore);
  const accrualsBetsStore = useLocalObservable(AccrualsBetsStore);
  const allAccrualsStore = useLocalObservable(AllAccrualsStore);
  const reportsStore = useLocalObservable(ReportsStore);
  const vacationsStore = useLocalObservable(VacationsStore);

  const stores = {
    authStore,
    paymentFormsStore,
    usersStore,
    employmentFormsStore,
    sidebarStore,
    employeesStore,
    extraPaymentsStore,
    departamentStore,
    accrualsDealsStore,
    accrualsBetsStore,
    allAccrualsStore,
    reportsStore,
    vacationsStore,
  };

  return (
    <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
  );
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useRootStore = () => {
  const rootStore = useContext(StoreContext);

  if (!rootStore) {
    throw new Error("useStore must be used within a StoreProvider");
  }

  return rootStore;
};
