import { makeAutoObservable } from "mobx";

import { createUser, deleteUser, getUsers, updateUser } from "src/shared/api";

export const UsersStore = () => {
  const store = {
    users: [],
    isLoading: false,
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalUsers: 0,
    searchTerm: "",
    selectedRole: "",

    setUsers(users) {
      this.users = users || [];
    },

    setTotalUsers(total) {
      this.totalUsers = total || 0;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    setPageSize(size) {
      this.pageSize = size;
    },

    setSearchTerm(term) {
      this.searchTerm = term;
    },

    setSelectedRole(role) {
      this.selectedRole = role;
      this.loadUsers();
    },

    resetFilters() {
      this.setSearchTerm("");
      this.setSelectedRole("");
      this.setCurrentPage(1);
      this.loadUsers();
    },

    async loadUsers() {
      try {
        this.setIsLoading(true);
        const params = new URLSearchParams();

        if (this.searchTerm) params.append("q", this.searchTerm);
        if (this.selectedRole) params.append("role", this.selectedRole);

        params.append("page", this.currentPage);
        params.append("limit", this.pageSize);

        const res = await getUsers(params);
        if (res.data) {
          this.setUsers(res.data.data);
          this.setTotalUsers(res.data.total);
          this.clearError();
        }
      } catch (error) {
        this.setError("Ошибка при загрузке пользователей");
      } finally {
        this.setIsLoading(false);
      }
    },

    async addUser(data) {
      this.setIsLoading(true);
      try {
        const res = await createUser(data);
        this.setUsers([...this.users]);
        this.setError(null);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          this.setError(error.response.data.detail);
        } else {
          this.setError("Ошибка при добавлении пользователя");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async updateUser(id, data) {
      this.setIsLoading(true);
      try {
        const res = await updateUser(id, data);
        if (res.data) {
          this.setUsers(
            this.users.map((user) =>
              user.id === id
                ? {
                    ...user,
                    ...res.data,
                    profile: { ...user.profile, ...res.data.profile },
                  }
                : user,
            ),
          );
          this.setError(null);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          this.setError(error.response.data.detail);
        } else {
          this.setError("Ошибка при обновлении данных пользователя.");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async deleteUser(id) {
      try {
        await deleteUser(id);
        this.setUsers(this.users.filter((user) => user.id !== id));
      } catch (error) {
        this.setError("Ошибка при удалении пользователя");
      }
    },
  };

  makeAutoObservable(store);
  return store;
};
