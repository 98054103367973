import { Pagination, Form } from "react-bootstrap";

import style from "./PaginationComponent.module.css";

function PaginationComponent(props) {
  const { itemsPerPage, totalItems, paginate, currentPage, onPageSizeChange } =
    props;

  const pageNumbers = [];
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  // if (pageCount === 1) return null;

  for (let i = 1; i <= pageCount; i += 1) {
    pageNumbers.push(i);
  }

  return (
    <div className={style.paginationStyleCentered}>
      <Pagination style={{ backgroundColor: "transparent", margin: "0" }}>
        {/* <Pagination.First
          onClick={() => paginate(1)}
          disabled={currentPage === 1}
        />
        <Pagination.Prev
          onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
          disabled={currentPage === 1}
        /> */}
        {pageNumbers.map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => paginate(number)}
            linkStyle={{
              backgroundColor: number === currentPage ? "#9469f3" : "",
              color: number === currentPage ? "white" : "#9469f3",
              borderColor: "transparent",
              fontWeight: "500",
              // fontSize: '18px'
            }}
          >
            {number}
          </Pagination.Item>
        ))}
        {/* <Pagination.Next
          onClick={() =>
            paginate(currentPage < pageCount ? currentPage + 1 : pageCount)
          }
          disabled={currentPage === pageCount}
        />
        <Pagination.Last
          onClick={() => paginate(pageCount)}
          disabled={currentPage === pageCount}
        /> */}
      </Pagination>
      {onPageSizeChange && (
        <div
          className={style.paginationControls}
          style={{ backgroundColor: "transparent" }}
        >
          <span>Отобразить элементов на странице: </span>
          <Form.Control
            as="select"
            value={itemsPerPage}
            onChange={(e) => onPageSizeChange(Number(e.target.value))}
            className={style.paginationStyleDropdonw}
          >
            <option value={1}>1</option>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Control>
        </div>
      )}
    </div>
  );
}

export default PaginationComponent;
