import { observer } from "mobx-react";
import { Navbar } from "react-bootstrap";

import { icMenuBurger } from "src/assets/icons/icons";
import styles from "src/shared/components/CustomNavbar/index.module.css";
import Loader from "src/shared/components/loaders/Loader";
import { useRootStore } from "src/shared/stores/initStore";

export const CustomNavbar = observer(({ handleShow }) => {
  const { authStore } = useRootStore();

  return (
    <Navbar data-bs-theme="dark" className={styles.customNavbar}>
      <button className={styles.openCanvas} onClick={handleShow} type="button">
        <img className={styles.iconBurger} src={icMenuBurger} alt="menu" />
      </button>
      <h5 className={styles.userRole}>
        {authStore.userData === undefined ? (
          <Loader />
        ) : (
          `Вы зашли как ${authStore.userData?.profile?.full_name || authStore.userData?.login}`
        )}
      </h5>
    </Navbar>
  );
});
