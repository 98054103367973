import { makeAutoObservable } from "mobx";

import { deleteAuthToken, getUsersMe, postAuth } from "src/shared/api";
import {
  clearStorage,
  getStorage,
  persistStorage,
} from "src/shared/stores/utils";

export const AuthStore = () => {
  const isAuth = getStorage("isAuth") === "true";

  const store = {
    isLoadingPostLogin: false,
    loginError: null,
    userData: null,
    isAuth: isAuth || false,

    setLoginError(error) {
      this.loginError = error;
    },

    setLoadingPostLogin(isLoading) {
      this.isLoadingPostLogin = isLoading;
    },

    setUserData(data) {
      this.userData = data;
    },

    setAuthStatus(status) {
      this.isAuth = status;
      persistStorage("isAuth", this.isAuth.toString());
    },

    async postLogin(data) {
      try {
        this.setLoadingPostLogin(true);
        this.setLoginError(null);
        const res = await postAuth(data);

        if (res.data.access_token) {
          persistStorage("accessToken", res.data.access_token);
          persistStorage("refreshToken", res.data.refresh_token);
          await this.getUser();
          this.setAuthStatus(true);
        } else {
          this.setAuthStatus(false);
        }
      } catch (error) {
        this.setLoginError("У вас нет прав для доступа к разделам сервиса");
        this.setAuthStatus(false);
      } finally {
        this.setLoadingPostLogin(false);
      }
    },

    async getUser() {
      try {
        const res = await getUsersMe();

        if (
          res.data.profile &&
          (res.data.profile.role === "accountant" ||
            res.data.profile.role === "department_head")
        ) {
          this.setUserData(res.data);
          this.setAuthStatus(true);
        } else {
          this.setLoginError("У вас нет прав для доступа к разделам сервиса");
          this.setAuthStatus(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
        }
      } catch (error) {
        this.setAuthStatus(false);
        console.error(error);
      }
    },

    async logout() {
      try {
        await deleteAuthToken();
        this.setAuthStatus(false);
        this.setUserData(null);
        clearStorage();
      } catch (error) {
        console.error("ошибка выхода", error);
      }
    },
  };

  makeAutoObservable(store);
  return store;
};
