import { useEffect, useState } from "react";

import { Modal, Button, Form } from "react-bootstrap";

import style from "src/shared/ui/modals/employmentForms/index.module.css";

export default function EmploymentUpdateForm({
  show,
  onHide,
  initialTitle,
  initialTaxPercentage,
  onUpdate,
  formId,
  error,
}) {
  const [title, setTitle] = useState(initialTitle);
  const [taxPercentage, setTaxPercentage] = useState(initialTaxPercentage);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSave = () => {
    if (title.trim() === "") {
      setErrorMessage("Необходимо заполнить название формы");
    } else if (Number.isNaN(taxPercentage) || taxPercentage < 0) {
      setErrorMessage("Некорректное значение процента налога");
    } else {
      onUpdate(formId, title.trim(), parseFloat(taxPercentage));
      setErrorMessage("");
    }
  };

  useEffect(() => {
    setTitle(initialTitle);
    setTaxPercentage(initialTaxPercentage);
    setErrorMessage(error);
  }, [initialTitle, initialTaxPercentage, error]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={style.modalContainer}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Изменение формы ТУ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="paymentFormTitle">
            <Form.Label>Название формы ТУ</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите новое название"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setErrorMessage("");
              }}
              isInvalid={!!errorMessage}
            />
          </Form.Group>
          <Form.Group controlId="taxPercentage">
            <Form.Label>Процент налога</Form.Label>
            <Form.Control
              type="number"
              placeholder="Введите процент налога"
              value={taxPercentage}
              onChange={(e) => {
                setTaxPercentage(e.target.value);
                setErrorMessage("");
              }}
              isInvalid={!!errorMessage}
            />
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Отмена
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Сохранить изменения
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
