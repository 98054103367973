import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { FormControl, Stack } from "react-bootstrap";

import {
  CustomButton,
  DepartmentFilterDropdown,
  PaginationComponent,
  ReusableBootstrapMonthPicker,
  ReusableTable,
  useDebounce,
  useSetTitle,
  useSizePageDepartment,
} from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./AccrualsDeals.module.css";
import { AccrualsDealsContent } from "./AccrualsDealsContent";
import { DealsAddEditModal, useDealsModal } from "./DealsAddEditModal";
import { thDealsArray } from "../lib";

export const AccrualsDeals = observer(() => {
  useSetTitle("Начисления - сделки");
  const { accrualsDealsStore } = useRootStore();
  const { isShow, handleOpen, handleClose } = useDealsModal();
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useSizePageDepartment();

  useEffect(() => {
    accrualsDealsStore.loadDeals();
    accrualsDealsStore.loadUnitsOfMeasurement();
  }, [accrualsDealsStore]);

  useEffect(() => {
    accrualsDealsStore.setSearchTerm(debouncedSearchTerm);
    accrualsDealsStore.loadDeals();
  }, [debouncedSearchTerm, accrualsDealsStore]);

  const handlePageChange = (page) => {
    accrualsDealsStore.setCurrentPage(page);
    accrualsDealsStore.loadDeals();
  };

  const handlePageSizeChange = (size) => {
    accrualsDealsStore.setPageSize(size);
    accrualsDealsStore.setCurrentPage(1);
    accrualsDealsStore.loadDeals();
  };

  const handleDepartmentToggle = (deptId) => {
    const newSelectedDepartments =
      accrualsDealsStore.selectedDepartments.includes(deptId)
        ? accrualsDealsStore.selectedDepartments.filter((id) => id !== deptId)
        : [...accrualsDealsStore.selectedDepartments, deptId];

    accrualsDealsStore.setSelectedDepartments(newSelectedDepartments);
    accrualsDealsStore.loadDeals();
  };

  const handleDepartmentReset = () => {
    accrualsDealsStore.setSelectedDepartments([]);
    accrualsDealsStore.loadDeals();
  };

  const handleMonthChange = (selectedDate) => {
    accrualsDealsStore.setSelectedMonth(selectedDate);
    accrualsDealsStore.loadDeals();
  };

  const toggleFilters = () => setFiltersVisible((prev) => !prev);

  return (
    <>
      <Stack gap={4}>
        <div className={style.filtersContainer}>
          <div className={style.buttons}>
            <CustomButton onClick={handleOpen}>Добавить сделку</CustomButton>
            <CustomButton onClick={toggleFilters}>
              {filtersVisible ? "Скрыть фильтры" : "Показать фильтры"}
            </CustomButton>
          </div>

          {filtersVisible && (
            <div className={style.filters}>
              <ReusableBootstrapMonthPicker
                selectedMonth={accrualsDealsStore.selectedMonth}
                onChange={handleMonthChange}
              />

              <DepartmentFilterDropdown
                selectedDepartments={accrualsDealsStore.selectedDepartments}
                onToggleDepartment={handleDepartmentToggle}
                onResetDepartments={handleDepartmentReset}
              />

              <FormControl
                className={style.searchInput}
                type="text"
                placeholder="Поиск"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className={style.leftSide}>
                <CustomButton onClick={() => accrualsDealsStore.resetFilters()}>
                  Сбросить фильтры
                </CustomButton>
              </div>
            </div>
          )}
        </div>

        <ReusableTable thArray={thDealsArray}>
          <AccrualsDealsContent
            PaginationComponent={
              <PaginationComponent
                itemsPerPage={accrualsDealsStore.pageSize}
                totalItems={accrualsDealsStore.totalDeals}
                paginate={handlePageChange}
                currentPage={accrualsDealsStore.currentPage}
                onPageSizeChange={handlePageSizeChange}
              />
            }
          />
        </ReusableTable>
      </Stack>

      <DealsAddEditModal show={isShow} handleClose={handleClose} deal={null} />
    </>
  );
});
