import { useState } from "react";

export const useConfirmModal = () => {
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const showConfirmModal = (e) => {
    if (e) e.stopPropagation();
    setIsConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModal(false);
  };

  return {
    showConfirmModal,
    closeConfirmModal,
    isConfirmModal,
  };
};
