import { makeAutoObservable } from "mobx";

import {
  createEmployment,
  deleteEmployment,
  getEmployment,
  updateEmployment,
} from "src/shared/api/employmentForms";

export const EmploymentFormStore = () => {
  const store = {
    employments: [],
    isLoading: false,
    error: null,

    setEmployments(employments) {
      this.employments = employments;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    async loadEmployments() {
      try {
        this.setIsLoading(true);
        const res = await getEmployment();
        this.setEmployments(res.data.data);
        this.setError(null);
      } catch (error) {
        this.setError("Ошибка при загрузке данных о занятости");
      } finally {
        this.setIsLoading(false);
      }
    },

    async addEmployment(data) {
      this.setIsLoading(true);
      try {
        await createEmployment(data);
        this.setEmployments([...this.employments]);
        this.setError(null);
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(error.response.data.detail.message);
        } else {
          this.setError("Ошибка при добавлении данных о занятости");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async updateEmployment(id, data) {
      this.setIsLoading(true);
      try {
        const res = await updateEmployment(id, data);
        if (res.data) {
          this.setEmployments(
            this.employments.map((employment) =>
              employment.id === id ? res.data : employment,
            ),
          );
          this.setError(null);
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(error.response.data.detail.message);
        } else {
          this.setError("Ошибка при обновлении данных о занятости");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async deleteEmployment(id) {
      try {
        await deleteEmployment(id);
        this.setEmployments(
          this.employments.filter((employment) => employment.id !== id),
        );
      } catch (error) {
        this.setError("Ошибка при удалении данных о занятости");
      }
    },
  };

  makeAutoObservable(store);
  return store;
};
