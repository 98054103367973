import { useState } from "react";

import { observer } from "mobx-react";
import { Modal, FormCheck, Dropdown } from "react-bootstrap";

import CustomButton from "src/shared/components/customButton/CustomButton";
import { useRootStore } from "src/shared/stores/initStore";

import s from "./index.module.css";

const FilterDepartments = observer((props) => {
  const {
    show,
    handleClose,
    departments,
    employmentForms,
    paymentForms,
    onApply,
    selectedEmploymentFormId,
    selectedPaymentFormId,
    setSelectedEmploymentFormId,
    setSelectedPaymentFormId,
  } = props;
  const { authStore } = useRootStore();
  const userRole = authStore.userData?.profile?.role;
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [showFiredOnly, setShowFiredOnly] = useState(false);

  const toggleDepartmentSelection = (deptId) => {
    setSelectedDepartments((prev) =>
      prev.includes(deptId)
        ? prev.filter((id) => id !== deptId)
        : [...prev, deptId],
    );
  };

  const handleKeyDown = (event, deptId) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      toggleDepartmentSelection(deptId);
    }
  };

  const handleApplyFilters = () => {
    onApply(
      selectedDepartments,
      showFiredOnly,
      selectedEmploymentFormId,
      selectedPaymentFormId,
    );
  };

  const handleResetFilters = () => {
    setSelectedDepartments([]);
    setShowFiredOnly(false);
    setSelectedEmploymentFormId("");
    setSelectedPaymentFormId("");
    onApply([]);
  };

  const handleResetFiltersForDepartmentHead = () => {
    setSelectedDepartments(departments.map((dept) => dept.id));
    setShowFiredOnly(false);
    setSelectedEmploymentFormId("");
    setSelectedPaymentFormId("");
    onApply(
      departments.map((dept) => dept.id),
      false,
      "",
      "",
    );
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Фильтр</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {departments.map((dept) => (
          <div
            key={dept.id}
            className={s.checkItem}
            onClick={() => toggleDepartmentSelection(dept.id)}
            onKeyDown={(event) => handleKeyDown(event, dept.id)}
            role="checkbox"
            tabIndex={0}
            aria-checked={selectedDepartments.includes(dept.id)}
          >
            <FormCheck
              type="checkbox"
              label={dept.title}
              checked={selectedDepartments.includes(dept.id)}
              onChange={() => {}}
              className={s.checkbox}
            />
          </div>
        ))}
        {userRole !== "department_head" && (
          <>
            <hr className={s.divider} />
            <Dropdown
              onSelect={(eventKey) => setSelectedPaymentFormId(eventKey)}
            >
              <Dropdown.Toggle
                variant="success"
                id="dropdown-payment-form"
                className={s.dropdownContainer}
              >
                {selectedPaymentFormId
                  ? paymentForms.find((p) => p.id === selectedPaymentFormId)
                      ?.title
                  : "Форма выплаты"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {paymentForms.map((form) => (
                  <Dropdown.Item key={form.id} eventKey={form.id}>
                    {form.title}
                  </Dropdown.Item>
                ))}
                <Dropdown.Item eventKey="">Без фильтра</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <hr className={s.divider} />

            <Dropdown
              onSelect={(eventKey) => setSelectedEmploymentFormId(eventKey)}
            >
              <Dropdown.Toggle
                variant="success"
                id="dropdown-employment-form"
                className={s.dropdownContainer}
              >
                {selectedEmploymentFormId
                  ? employmentForms.find(
                      (e) => e.id === selectedEmploymentFormId,
                    )?.title
                  : "Форма ТУ"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {employmentForms.map((form) => (
                  <Dropdown.Item key={form.id} eventKey={form.id}>
                    {form.title}
                  </Dropdown.Item>
                ))}
                <Dropdown.Item eventKey="">Без фильтра</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
        <hr className={s.divider} />

        <div
          className={s.checkItem}
          onClick={() => setShowFiredOnly(!showFiredOnly)}
          onKeyDown={(event) => handleKeyDown(event, null, true)}
          role="checkbox"
          tabIndex={0}
          aria-checked={showFiredOnly}
        >
          <FormCheck
            type="checkbox"
            label="Показать только уволенных сотрудников"
            checked={showFiredOnly}
            onChange={() => {}}
            className={s.checkbox}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className={s.footer}>
        {userRole === "department_head" ? (
          <CustomButton onClick={handleResetFiltersForDepartmentHead}>
            Сбросить фильтры
          </CustomButton>
        ) : (
          <CustomButton onClick={handleResetFilters}>
            Сбросить все фильтры
          </CustomButton>
        )}
        <CustomButton onClick={handleApplyFilters}>Применить</CustomButton>
      </Modal.Footer>
    </Modal>
  );
});

export default FilterDepartments;
