import Api from "src/shared/api/axiosWrapper";

const URL = "employment_forms";

export const getEmployment = () =>
  Api.get({
    url: `${URL}`,
  });

export const createEmployment = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const updateEmployment = (id, data) =>
  Api.patch({
    url: `${URL}/${id}`,
    data,
  });

export const deleteEmployment = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });
