export default class Api {
  constructor(axiosInstance) {
    this.axiosInstance = axiosInstance
  }

  get(config) {
    return this.axiosInstance({ ...config, method: "GET" })
  }

  post(config) {
    return this.axiosInstance({ ...config, method: "POST" })
  }

  delete(config) {
    return this.axiosInstance({ ...config, method: "DELETE" })
  }

  put(config) {
    return this.axiosInstance({ ...config, method: "PUT" })
  }

  patch(config) {
    return this.axiosInstance({ ...config, method: "PATCH" })
  }
}
