import { useState, useEffect } from "react";

import ru from "date-fns/locale/ru";
import { observer } from "mobx-react";
import moment from "moment";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

import "react-bootstrap-typeahead/css/Typeahead.css";

import { CustomButton } from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./VacationAddEditModal.module.css";

export const VacationAddEditModal = observer((props) => {
  const { show, handleClose, vacation } = props;
  const { vacationsStore, departamentStore, employeesStore } = useRootStore();

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [vacationDays, setVacationDays] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Обновляем данные, если модалка открыта для редактирования отпуска
  useEffect(() => {
    if (vacation) {
      setSelectedDepartment(vacation.department.id);
      setSelectedEmployee({
        id: vacation.employee.id,
        label: vacation.employee.fullname,
      });
      setStartDate(new Date(vacation.begin_date)); // Преобразуем строку в Date
      setEndDate(new Date(vacation.end_date));
      setVacationDays(vacation.total_days);
      setSelectedType({
        value: vacation.vacation_type,
        label: vacation.vacation_type,
      });
    } else {
      setSelectedDepartment("");
      setSelectedEmployee(null);
      setStartDate("");
      setEndDate("");
      setVacationDays("");
      setSelectedType(null);
      setErrorMessage("");
    }
  }, [vacation, show]);

  useEffect(() => {
    departamentStore.loadDepartaments();
    employeesStore.loadEmployees();
    vacationsStore.loadVacationTypes();
  }, [departamentStore, employeesStore, vacationsStore]);

  useEffect(() => {
    if (selectedDepartment) {
      const employees =
        employeesStore.getEmployeesByDepartment(selectedDepartment);
      setFilteredEmployees(employees);
    } else {
      setFilteredEmployees([]);
    }
  }, [selectedDepartment, employeesStore]);

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const days = (end - start) / (1000 * 60 * 60 * 24);
      if (days > 0) {
        setVacationDays(days + 1);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (vacationsStore.error) setErrorMessage(vacationsStore.error);
  }, [vacationsStore.error]);

  // Обработка выбора диапазона дат в DatePicker
  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleSave = async () => {
    setFormSubmitted(true);

    if (!selectedDepartment) {
      setErrorMessage("Пожалуйста выберите отдел.");
      return;
    }
    if (!selectedEmployee) {
      setErrorMessage("Пожалуйста выберите сотрудника.");
      return;
    }
    if (!startDate || !endDate) {
      setErrorMessage("Пожалуйста выберите даты начала и конца отпуска.");
      return;
    }
    if (!vacationDays || vacationDays <= 0) {
      setErrorMessage("Количество дней отпуска должно быть больше нуля.");
      return;
    }
    if (!selectedType) {
      setErrorMessage("Пожалуйста выберите тип отпуска.");
      return;
    }

    const vacationData = {
      department_id: selectedDepartment,
      employee_id: selectedEmployee.id,
      begin_date: startDate,
      end_date: endDate,
      total_days: vacationDays,
      vacation_type: selectedType.label,
    };

    try {
      if (vacation) {
        await vacationsStore.updateVacation(vacation.id, vacationData);
      } else {
        await vacationsStore.addVacation(vacationData);
      }

      if (!vacationsStore.error) {
        setErrorMessage("");
        vacationsStore.clearError();
        handleClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setErrorMessage(
          error.response.data.detail || "Ошибка 409: Операция не разрешена.",
        );
      } else {
        setErrorMessage("Произошла ошибка при сохранении отпуска.");
      }
    }
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption.value);
    setSelectedEmployee(null);
    employeesStore.setSelectedDepartments([selectedOption.value]);
    employeesStore.loadEmployees();
  };

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
  };

  const handleDaysInputChange = (e) => {
    const { value } = e.target;
    if (!Number.isNaN(Number(value)) && value >= 0) {
      setVacationDays(value);
    }
  };

  const departments = departamentStore.departaments.map((dept) => ({
    value: dept.id,
    label: dept.title,
  }));

  const vacationTypes = vacationsStore.vacationTypes.map((type) => ({
    value: type,
    label: type,
  }));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {vacation ? "Редактирование отпуска" : "Создание отпуска"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage("")}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        <Form>
          <Form.Group controlId="formDepartment">
            <Form.Label>Отдел</Form.Label>
            <Select
              maxMenuHeight={200}
              value={departments.find(
                (dept) => dept.value === selectedDepartment,
              )}
              onChange={handleDepartmentChange}
              options={departments}
              placeholder="Выберите отдел"
            />
          </Form.Group>
          <Form.Group controlId="formEmployee" className={style.mt_10}>
            <Form.Label>Сотрудник</Form.Label>
            <Typeahead
              id="employee-search"
              labelKey="label"
              onInputChange={(query) => {
                employeesStore.setSearchTerm(query);
                if (selectedDepartment) {
                  employeesStore.loadEmployees();
                }
              }}
              onChange={(selected) => {
                if (selected.length > 0) {
                  handleEmployeeChange(selected[0]);
                } else {
                  setSelectedEmployee(null);
                }
              }}
              options={employeesStore.employees.map((emp) => ({
                id: emp.id,
                label: emp.fullname,
              }))}
              placeholder="Введите имя сотрудника..."
              selected={selectedEmployee ? [selectedEmployee] : []}
              isDisabled={!selectedDepartment}
            />
          </Form.Group>
          <Form.Group
            controlId="formDateRange"
            className={style.mt_10}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Label>Выберите диапазон дат</Form.Label>
            <DatePicker
              locale="ru"
              className={style.datePicker}
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateRangeChange}
              withPortal
              showMonthDropdown
              showYearDropdown
              // isClearable
              placeholderText="Выберите даты"
              dateFormat="dd.MM.yyyy"
            />
          </Form.Group>
          <Form.Group controlId="formDays" className={style.mt_10}>
            <Form.Label>Дней в отпуске</Form.Label>
            <Form.Control
              type="number"
              value={vacationDays}
              onChange={handleDaysInputChange}
              min="0"
            />
          </Form.Group>
          <Form.Group controlId="formType" className={style.mt_10}>
            <Form.Label>Тип отпуска</Form.Label>
            <Select
              maxMenuHeight={200}
              value={vacationTypes.find(
                (type) => type.value === selectedType?.value,
              )}
              onChange={setSelectedType}
              options={vacationTypes}
              placeholder="Выберите тип отпуска"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={style.footer}>
        <Button
          variant="secondary"
          onClick={handleClose}
          className={style.cancelBTN}
        >
          Закрыть
        </Button>
        <CustomButton onClick={handleSave}>Сохранить</CustomButton>
      </Modal.Footer>
    </Modal>
  );
});
