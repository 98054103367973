import styles from "src/pages/departmentHead/index.module.css";

export default function DepartmentHead() {
  return (
    <div className={styles.containerTest}>
      <h1>DepartmentHead</h1>
      <h1>DepartmentHead</h1>
      <h1>DepartmentHead</h1>
      <h1>DepartmentHead</h1>
      <h1>DepartmentHead</h1>
      <h1>DepartmentHead</h1>
    </div>
  );
}
