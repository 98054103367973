import { useState } from "react";

import { observer } from "mobx-react";
import { Offcanvas } from "react-bootstrap";

import { icExitButton, icLogoMini } from "src/assets/icons/icons";
import { CustomNavbar } from "src/shared/components/CustomNavbar/CustomNavbar";
import { AccountantMenu, DepHeadMenu } from "src/shared/components/Sidebar";
import styles from "src/shared/components/Sidebar/index.module.css";
import { useRootStore } from "src/shared/stores/initStore";

const Sidebar = observer(() => {
  const { authStore } = useRootStore();
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLogout = () => authStore.logout();
  const userRole = authStore.userData?.profile?.role;

  return (
    <>
      <CustomNavbar handleShow={handleShow} />

      <Offcanvas className={styles.container} show={show} onHide={handleClose}>
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          className={styles.closeCanvasButton}
        />
        <img className={styles.logo} src={icLogoMini} alt="logo" />
        <Offcanvas.Body className={styles.offcanvasBodyCentered}>
          {userRole === "accountant" && (
            <AccountantMenu onClose={handleClose} />
          )}
          {userRole === "department_head" && (
            <DepHeadMenu onClose={handleClose} />
          )}

          <button
            className={styles.exitButton}
            type="button"
            onClick={handleLogout}
          >
            <img
              className={styles.exitButtonIcon}
              src={icExitButton}
              alt="exit-button"
            />
            Выход
          </button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
});

export default Sidebar;
