import Api from "src/shared/api/axiosWrapper";

const URL = "payment_forms";

export const getPaymentForm = () =>
  Api.get({
    url: `${URL}`,
  });

export const createPaymentForm = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const updatePaymentForm = (id, data) =>
  Api.patch({
    url: `${URL}/${id}`,
    data,
  });

export const deletePaymentForm = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });

export const getPaymentFormActTypes = () =>
  Api.get({
    url: `${URL}/act_types`,
  });
