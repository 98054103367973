import Api from "src/shared/api/axiosWrapper";

const URL = "departments";

export const addDepartament = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const getDepartaments = (params) =>
  Api.get({
    url: `${URL}`,
    params,
  });

export const updateDepartament = (id, data) =>
  Api.put({
    url: `${URL}/${id}`,
    data,
  });

export const getDepartmentById = (departmentId) =>
  Api.get({
    url: `${URL}/${departmentId}`,
  });

export const deleteDepartament = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });

export const addDepEmployees = async (departmentId, employeesIds) => {
  const url = `${URL}/employees/${departmentId}`;
  const response = await Api.post({
    url,
    data: { employees_ids: employeesIds },
  });
  return response;
};

export const deleteDepEmployyes = (departmentId, employeesIds) => {
  const url = `${URL}/employees/${departmentId}`;
  return Api.delete({
    url,
    data: { employees_ids: employeesIds },
  });
};
