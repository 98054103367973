import Api from "src/shared/api/axiosWrapper";

const URL = "vacations";

export const getVacations = (params) =>
  Api.get({
    url: `${URL}`,
    params,
  });

export const createVacation = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const getVacationTypes = () =>
  Api.get({
    url: `${URL}/types`,
  });

export const updateVacation = (id, data) =>
  Api.patch({
    url: `${URL}/${id}`,
    data,
  });

export const deleteVacation = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });
