/* eslint-disable react/no-array-index-key */
import { Table } from "react-bootstrap";

import style from "./AllAccrualsTable.module.css";

export default function AllAccrualsTable(props) {
  const { thArray, children, extraColumns, userRole } = props;

  const tableHeadClass =
    userRole === "accountant"
      ? style.tableHeadAccountant
      : style.tableHeadDepHead;

  return (
    <div className={style.tableContainer}>
      <div className={style.tableWrapper}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className={tableHeadClass}>
                {thArray.map((item) => (
                  <span key={item}>{item}</span>
                ))}
                {extraColumns &&
                  extraColumns.map((span, index) => (
                    <span key={`${span}-${index}`}>{span}</span>
                  ))}
              </th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </Table>
      </div>
    </div>
  );
}
