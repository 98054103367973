import { icTrash } from "src/assets/icons/icons";

import style from "./style.module.css";

function DeleteButton({ handleDelete, someId, someTitle }) {
  return (
    <button
      type="button"
      className={style.buttonDeleteBackground}
      onClick={(e) => {
        e.stopPropagation();
        handleDelete(e, someId, someTitle);
      }}
    >
      <img className={style.iconDelete} src={icTrash} alt="delete" />
    </button>
  );
}

export default DeleteButton;
