import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Modal, Button, Form, Dropdown, Alert } from "react-bootstrap";

import { CustomButton } from "src/shared/components";
import { useRootStore } from "src/shared/stores/initStore";
import { FormDropdown, FormGroup } from "src/shared/ui/controls";

import style from "./index.module.css";
import { handleTaxpayerIdChange } from "./lib";

export const EditEmployee = observer((props) => {
  const taxPayerIdLength = 12;
  const {
    employmentFormsStore,
    paymentFormsStore,
    departamentStore,
    authStore,
  } = useRootStore();
  const userRole = authStore.userData?.profile?.role;
  const isAccountant = userRole === "accountant";

  const { initialData } = props;

  const [formData, setFormData] = useState({
    fullname: initialData ? initialData.fullname : "",
    nickname: initialData ? initialData.nickname : "",
    salary: initialData ? initialData.salary : 0,
    regular_surcharge: initialData ? initialData.regular_surcharge : 0,
    regular_bets_num: initialData ? initialData.regular_bets_num : 0,
    regular_bets_price: initialData ? initialData.regular_bets_price : 0,
    payment_form: initialData ? initialData.payment_form : null,
    employment_form: initialData ? initialData.employment_form : null,
    departments: initialData ? initialData.departments : [],
    job_title: initialData ? initialData.job_title : "",
    taxpayer_id: initialData ? initialData.taxpayer_id : null,
    is_fired: initialData ? initialData.is_fired : false,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    employmentFormsStore.loadEmployments();
    paymentFormsStore.loadForms();
    departamentStore.loadDepartaments();
  }, [employmentFormsStore, paymentFormsStore, departamentStore]);

  const validateTaxpayerId = (value) => {
    if (!value) return true;
    return value && value.length === taxPayerIdLength;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelect = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateTaxpayerId(formData.taxpayer_id)) {
      setErrorMessage(`ИНН должен состоять из ${taxPayerIdLength} цифр`);
      return;
    };

    setErrorMessage(null);

    props.onSave({
      ...formData,
      employment_form_id: formData.employment_form?.id,
      payment_form_id: formData.payment_form?.id,
      department_ids: formData.departments.map((dept) => dept.id),
    });
  };

  const handleSelectDepartment = (eventKey) => {
    const department = departamentStore.departaments.find(
      (dept) => dept.id === eventKey,
    );
    if (
      department &&
      !formData.departments.some((dept) => dept.id === department.id)
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        departments: [...prevFormData.departments, department],
      }));
    }
  };

  const handleRemoveDepartment = (id) => {
    setFormData({
      ...formData,
      departments: formData.departments.filter((dept) => dept.id !== id),
    });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      className={style.modalContainer}
    >
      <Modal.Header closeButton>
        <Modal.Title>Редактирование сотрудника</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage(null)}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <FormGroup
            controlId="formFullName"
            label="ФИО"
            type="text"
            name="fullname"
            required
            value={formData.fullname}
            onChange={handleChange}
            placeholder="Введите полное имя"
          />

          <FormGroup
            controlId="formNickname"
            label="Никнейм"
            type="text"
            name="nickname"
            required={false}
            value={formData.nickname}
            onChange={handleChange}
            placeholder="Введите никнейм"
            className={style.styleFormMargin}
          />

          {isAccountant && (
            <FormGroup
              controlId="formTaxpayerId"
              label="ИНН"
              type="text"
              name="taxpayer_id"
              value={formData.taxpayer_id || ""}
              onChange={(e) => handleTaxpayerIdChange(e, formData, setFormData, taxPayerIdLength)}
              placeholder="Введите ИНН"
              className={style.styleFormMargin}
              maxLength={taxPayerIdLength}
            />
          )}

          <FormGroup
            controlId="formSalary"
            label="Оклад"
            type="number"
            min="0"
            step="0.01"
            name="salary"
            required
            value={formData.salary}
            onChange={handleChange}
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formRegularSurcharge"
            label="Постоянная доплата"
            type="number"
            min="0"
            step="0.01"
            name="regular_surcharge"
            required
            value={formData.regular_surcharge}
            placeholder="Введите доплату"
            onChange={handleChange}
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formRegularBetsNum"
            label="Постоянное кол-во ставок"
            type="number"
            min="0"
            name="regular_bets_num"
            value={formData.regular_bets_num}
            onChange={handleChange}
            placeholder="Введите кол-во ставок"
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formRegularBetsPrice"
            label="Цена ставки по-умолчанию"
            type="number"
            min="0"
            step="0.01"
            name="regular_bets_price"
            value={formData.regular_bets_price || ""}
            onChange={handleChange}
            placeholder="Введите цену ставки"
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formJobTitle"
            label="Должность"
            type="text"
            name="job_title"
            required={false}
            value={formData.job_title}
            onChange={handleChange}
            placeholder="Введите должность"
            className={style.styleFormMargin}
          />

          {userRole !== "department_head" && (
            <>
              <FormDropdown
                controlId="formPaymentForm"
                label="Форма выплаты"
                options={paymentFormsStore.forms}
                selectedOption={formData.payment_form}
                onSelect={(selectedOption) =>
                  handleSelect("payment_form", selectedOption)
                }
                className={style.styleFormMargin}
                placeholder="Выберите форму выплаты"
              />

              <FormDropdown
                controlId="formEmploymentForm"
                label="Форма занятости"
                options={employmentFormsStore.employments}
                selectedOption={formData.employment_form}
                onSelect={(selectedOption) =>
                  handleSelect("employment_form", selectedOption)
                }
                className={style.styleFormMargin}
                placeholder="Выберите форму ТУ"
              />

              <Form.Group
                controlId="formPaymentForm"
                className={style.styleFormMargin}
              >
                <Form.Label>Отделы</Form.Label>
                <Dropdown onSelect={handleSelectDepartment}>
                  <Dropdown.Toggle className={style.dropdownContainer}>
                    Выберите отделы
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {departamentStore.departaments.map((department) => (
                      <Dropdown.Item
                        key={department.id}
                        eventKey={department.id}
                      >
                        {department.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div className={style.fieldForDeportaments}>
                  {formData.departments.map((dept) => (
                    <span key={dept.id} className={style.selectedDeportations}>
                      {dept.title}
                      <button
                        type="button"
                        className={style.buttonBackground}
                        onClick={() => handleRemoveDepartment(dept.id)}
                      >
                        &#9746;
                      </button>
                    </span>
                  ))}
                </div>
              </Form.Group>
            </>
          )}

          <Form.Group
            controlId="formDismissed"
            className={style.styleFormMargin}
          >
            <Form.Check
              type="checkbox"
              label="Уволен"
              name="is_fired"
              checked={formData.is_fired}
              onChange={handleChange}
            />
          </Form.Group>
          <div className={style.btnContainer}>
            <CustomButton onClick={props.onHide}>Закрыть</CustomButton>
            <CustomButton type="submit">Сохранить</CustomButton>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
});
