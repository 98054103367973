import styles from "./index.module.css";

export default function TableWrapper({ isMoreWidth = false, children }) {
  return (
    <div className={styles.container}>
      <div
        className={`${styles.tableContainer} ${isMoreWidth && styles.moreWidthContainer}`}
      >
        {children}
      </div>
    </div>
  );
}
