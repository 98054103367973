import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Dropdown, FormControl, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  useSetTitle,
  CustomButton,
  useDebounce,
  PaginationComponent,
} from "src/shared";
import { DeleteButton } from "src/shared/components/customButtonTrash";
import { useRootStore } from "src/shared/stores/initStore";
import ConfirmationModal from "src/shared/ui/modals/modalConfirm/ConfirmationModal";

import style from "./style.module.css";
import { useDepartamentNavigation } from "../lib/utils";

const DepartamentView = observer(() => {
  useSetTitle("Отделы - Physical");
  const { navigateToCreateDepartament } = useDepartamentNavigation();
  const { departamentStore, usersStore } = useRootStore();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentFormId, setCurrentFormId] = useState(null);
  const [currentFormTitle, setCurrentFormTitle] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const navigate = useNavigate();

  const departmentHeads = usersStore.users.filter(
    (user) => user.profile.role === "department_head",
  );

  useEffect(() => {
    departamentStore.setSearchTerm(debouncedSearchTerm);
    departamentStore.loadDepartaments();
  }, [debouncedSearchTerm, departamentStore]);

  useEffect(() => {
    departamentStore.loadDepartaments();
    usersStore.loadUsers();
  }, [departamentStore, usersStore]);

  const handleDeleteClick = (departmentId, departmentTitle) => {
    setCurrentFormId(departmentId);
    setCurrentFormTitle(departmentTitle);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    departamentStore.deleteDepartament(currentFormId);
    setShowDeleteModal(false);
  };

  const handleNavigateToEdit = (departmentId) => {
    navigate(`/departments/${departmentId}/edit`);
  };

  const resetFilters = () => {
    departamentStore.resetFilters();
    setSearchTerm("");
  };

  const handleHeadToggle = (headId) => {
    const newSelectedHeads = departamentStore.departmentHeadIds.includes(headId)
      ? departamentStore.departmentHeadIds.filter((id) => id !== headId)
      : [...departamentStore.departmentHeadIds, headId];

    departamentStore.setDepartmentHeadIds(newSelectedHeads);
    departamentStore.loadDepartaments();
  };

  const handleHeadReset = () => {
    departamentStore.setDepartmentHeadIds([]);
    departamentStore.loadDepartaments();
  };

  const handlePageChange = (page) => {
    departamentStore.setCurrentPage(page);
    departamentStore.loadDepartaments();
  };

  const handlePageSizeChange = (size) => {
    departamentStore.setPageSize(size);
    departamentStore.setCurrentPage(1);
    departamentStore.loadDepartaments();
  };

  return (
    <>
      <div className={style.buttonsContainerTop}>
        <CustomButton onClick={navigateToCreateDepartament}>
          Добавить отдел
        </CustomButton>
        <div className={style.rightSide}>
          <Dropdown autoClose="outside">
            <Dropdown.Toggle className={style.dropdownContainer}>
              {departamentStore.departmentHeadIds.length === 0
                ? "Выберите начальников"
                : `Выбрано начальников: ${departamentStore.departmentHeadIds.length}`}
            </Dropdown.Toggle>
            <Dropdown.Menu className={style.scrollableDropdownMenu}>
              {departmentHeads.map((head) => (
                <Dropdown.Item
                  key={head.id}
                  onClick={() => handleHeadToggle(head.id)}
                  active={departamentStore.departmentHeadIds.includes(head.id)}
                >
                  {head.profile.full_name}
                </Dropdown.Item>
              ))}
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleHeadReset}>
                Сбросить фильтры
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <FormControl
            className={style.searchInput}
            type="text"
            placeholder="Поиск"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <CustomButton onClick={resetFilters}>Сбросить фильтры</CustomButton>

        </div>
      </div>

      <div className={style.tableScrollWrapper}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className={style.tableHead}>
                <span>Название отдела</span>
                <span>ФИО руководителей</span>
                <span>Кол-во сотрудников</span>
                <span>{}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {departamentStore.departaments.map((departament) => (
              <tr
                key={departament.id}
                onClick={() => handleNavigateToEdit(departament.id)}
              >
                <td className={style.tableTitle}>
                  <span>{departament.title}</span>
                  <span>
                    {departament.heads
                      .map((head) => head.profile.full_name)
                      .join(", ") || "-"}
                  </span>
                  <span className={style.countSpan}>
                    {departament.employees_qty}
                  </span>
                  <DeleteButton
                    handleDelete={() =>
                      handleDeleteClick(departament.id, departament.title)
                    }
                    someId={departament.id}
                    someTitle={departament.title}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          itemsPerPage={departamentStore.pageSize}
          totalItems={departamentStore.totalDepartments}
          paginate={handlePageChange}
          currentPage={departamentStore.currentPage}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>

      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
        title="Подтверждение удаления"
      >
        Вы действительно хотите удалить {currentFormTitle}?
      </ConfirmationModal>
    </>
  );
});

export default DepartamentView;
