import Api from "src/shared/api/axiosWrapper";

const URL = "reports";

export const getAccruals = (params) =>
  Api.get({
    url: `${URL}/accruals`,
    params,
  });

export const getEmployeeAccruals = (employee_id, params) =>
  Api.get({
    url: `${URL}/employee_accruals/${employee_id}`,
    params,
  });
