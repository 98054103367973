import axios from "axios";

export const postRefresh = (refreshToken, accessToken) => {
  const res = axios.post({
    url: `${URL}/refresh`,
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
    data: {
      access_token: accessToken,
    },
  });

  return res;
};
