import { observer } from "mobx-react";
import { NavLink } from "react-router-dom";

import styles from "src/shared/components/Sidebar/index.module.css";
import Spinner from "src/shared/components/loaders/Spinner";
import { useRootStore } from "src/shared/stores/initStore";

export const AccountantMenu = observer(({ onClose }) => {
  const { authStore, sidebarStore } = useRootStore();
  const userRole = authStore.userData?.profile?.role;

  const handleNavlinkClick = () => {
    onClose();
  };

  return (
    <div>
      {userRole === undefined ? (
        <Spinner />
      ) : (
        (userRole === "accountant" || userRole === "department_head") && (
          <>
            {/* <a
              className={`${styles.dropdownButton} ${sidebarStore.dropdownReports ? styles.dropdownButtonAction : ""}`}
              href="#!"
              onClick={() => sidebarStore.toggleDropdownReports()}
            >
              Отчёты
            </a>
            {sidebarStore.dropdownReports && (
              <ul className={styles.list}>
                <li>
                  <NavLink onClick={handleNavlinkClick} to="home">
                    Все отчёты *
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={handleNavlinkClick} to="home">
                    Платёжные ведомости *
                  </NavLink>
                </li>
              </ul>
            )} */}

            <NavLink
              onClick={handleNavlinkClick}
              to="accruals/allAccrulas"
              className={styles.dropdownButton}
            >
              Все начисления
            </NavLink>

            <NavLink
              onClick={handleNavlinkClick}
              to="accruals/reports"
              className={styles.dropdownButton}
            >
              Отчёты
            </NavLink>

            <NavLink
              onClick={handleNavlinkClick}
              to="employees"
              className={styles.dropdownButton}
            >
              Сотрудники
            </NavLink>

            <a
              className={`${styles.dropdownButton} ${sidebarStore.dropdownDirectory ? styles.dropdownButtonAction : null}`}
              href="#!"
              onClick={() => sidebarStore.toggleDropdownDirectory()}
            >
              Справочник и настройки
            </a>
            {sidebarStore.dropdownDirectory && (
              <ul className={styles.list}>
                <li>
                  <NavLink onClick={handleNavlinkClick} to="employmentForms">
                    Формы ТУ
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={handleNavlinkClick} to="paymentForms">
                    Формы выплат
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={handleNavlinkClick} to="departament">
                    Отделы
                  </NavLink>
                </li>
                {authStore.userData?.superuser ? (
                  <li>
                    <NavLink onClick={handleNavlinkClick} to="usersManagment">
                      Пользователи
                    </NavLink>
                  </li>
                ) : (
                  <NavLink to="home" />
                )}
              </ul>
            )}
          </>
        )
      )}
    </div>
  );
});
