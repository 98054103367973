export const getDepartmentHeads = (users) =>
  users.filter((user) => user.profile.role === "department_head");

export const addSelectedHead = (selectedHeads, headId) => {
  if (!selectedHeads.includes(headId)) {
    return [...selectedHeads, headId];
  }
  return selectedHeads;
};

export const removeSelectedHead = (selectedHeads, headId) =>
  selectedHeads.filter((id) => id !== headId);

export const handleSubmitDepartment = async (departamentStore, data) => {
  try {
    await departamentStore.addDepartament(data);
    return true;
  } catch (error) {
    console.error("Error creating department:", error);
    return false;
  }
};
