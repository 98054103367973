import { makeAutoObservable } from "mobx";
import moment from "moment";

import {
  createDeals,
  deleteDeals,
  getDeals,
  updateDeals,
  getUnitsOfMeasurement,
} from "src/shared/api";

export const AccrualsDealsFormStore = () => {
  const store = {
    deals: [],
    unitsOfMeasurement: [],
    isLoading: false,
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalDeals: 0,
    selectedDepartments: [],
    searchTerm: "",
    selectedMonth: new Date(),
    selectedUnit: "",

    setDeals(deals) {
      this.deals = deals || [];
    },

    setUnitsOfMeasurement(units) {
      this.unitsOfMeasurement = units;
    },

    setTotalDeals(total) {
      this.totalDeals = total;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    setPageSize(size) {
      this.pageSize = size;
    },

    setSelectedDepartments(departments) {
      this.selectedDepartments = departments;
    },

    setSearchTerm(term) {
      this.searchTerm = term;
    },

    setSelectedMonth(date) {
      this.selectedMonth = date;
    },

    setSelectedUnit(unit) {
      this.selectedUnit = unit;
    },

    resetFilters() {
      this.setSelectedDepartments([]);
      this.setSearchTerm("");
      this.setSelectedMonth(new Date());
      this.setSelectedUnit("");
      this.setCurrentPage(1);
      this.loadDeals();
    },

    async loadDeals() {
      try {
        this.setIsLoading(true);
        const params = new URLSearchParams({
          date: moment(this.selectedMonth).format("YYYY-MM-DD"),
          // date: this.selectedMonth.toISOString().split("T")[0],
          q: this.searchTerm,
          unit: this.selectedUnit,
          page: this.currentPage,
          limit: this.pageSize,
        });

        if (this.selectedDepartments.length > 0) {
          this.selectedDepartments.forEach((deptId) => {
            params.append("department_ids", deptId);
          });
        }

        const res = await getDeals(params);
        if (res.data) {
          this.setDeals(res.data.data);
          this.setTotalDeals(res.data.total);
          this.clearError();
        }
      } catch (error) {
        this.setError("Ошибка при загрузке данных о сделках");
      } finally {
        this.setIsLoading(false);
      }
    },

    async addDeal(data) {
      this.setIsLoading(true);
      try {
        const res = await createDeals(data);
        this.setDeals([...this.deals]);
        this.setError(null);
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(error.response.data.detail.message);
        } else {
          this.setError("Ошибка при добавлении данных о занятости");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async loadUnitsOfMeasurement(query = "") {
      try {
        this.setIsLoading(true);
        const res = await getUnitsOfMeasurement(query);
        if (res.data) {
          this.setUnitsOfMeasurement(res.data);
        }
        this.setError(null);
      } catch (error) {
        this.setError("Ошибка при загрузке единиц измерения");
      } finally {
        this.setIsLoading(false);
      }
    },

    async updateDeal(id, data) {
      this.setIsLoading(true);
      try {
        const res = await updateDeals(id, data);
        if (res.data) {
          this.setDeals(
            this.deals.map((deal) => (deal.id === id ? res.data : deal)),
          );
          this.setError(null);
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(error.response.data.detail.message);
        } else {
          this.setError("Ошибка при обновлении данных о занятости");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async deleteDeal(id) {
      try {
        await deleteDeals(id);
        this.setDeals(this.deals.filter((deal) => deal.id !== id));
      } catch (error) {
        this.setError("Ошибка при удалении данных о занятости");
      }
    },
  };

  makeAutoObservable(store);
  return store;
};
