import { Outlet } from "react-router-dom"

import Sidebar from "src/shared/components/Sidebar/Sidebar"

export default function ProtectedRoutes() {
  return (
    <>
      <Sidebar />
      <Outlet />
    </>
  )
}
