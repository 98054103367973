import { useRef, useState, useEffect } from "react";

import { Dropdown, Form, FormCheck } from "react-bootstrap";

import styles from "./CustomDropdown.module.css";

export default function CustomDropdown(props) {
  const {
    controlId,
    selectedItems,
    items,
    onToggleSelection,
    onReset,
    dropdownLabel,
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Form.Group controlId={controlId} style={{ width: "fit-content" }}>
      <Dropdown
        autoClose={false}
        show={dropdownOpen}
        ref={dropdownRef}
        onToggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <Dropdown.Toggle className={styles.dropdownContainer}>
          {selectedItems.length === 0
            ? dropdownLabel
            : `Выбрано: ${selectedItems.length}`}
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.scrollableDropdownMenu}>
          {items.map((item) => (
            <Dropdown.Item
              key={item.id}
              onClick={() => onToggleSelection(item.id)}
              className="d-flex align-items-center"
            >
              <FormCheck
                checked={selectedItems.includes(item.id)}
                label={item.label}
                readOnly
              />
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
          <Dropdown.Item onClick={onReset}>Сбросить фильтры</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
}
