import { Button, Modal } from "react-bootstrap";

const footerStyle = {
  display: "flex",
  justifyContent: "center",
};

export default function ConfirmationModal(props) {
  const { show, onHide, onConfirm, title, children } = props;
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer style={footerStyle}>
        <Button variant="secondary" onClick={onHide}>
          Отмена
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Подтвердить
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
