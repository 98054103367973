import { useState } from "react";

export const useVacationsModal = () => {
  const [isShow, setIsShow] = useState(false);

  const handleOpen = () => setIsShow(true);
  const handleClose = () => setIsShow(false);

  return {
    isShow,
    handleOpen,
    handleClose,
  };
};
