import Api from "src/shared/api/axiosWrapper";

const URL = "surcharges";

export const getSurcharges = (params) =>
  Api.get({
    url: `${URL}`,
    params,
  });

export const createMonthSurcharge = (data) =>
  Api.post({
    url: `${URL}/month`,
    data,
  });

export const getExtraPaymentsTypes = (type) =>
  Api.get({
    url: `${URL}/types?q=${type}`,
  });

export const getSurchargeById = (id) =>
  Api.get({
    url: `${URL}/${id}`,
  });

export const createSurcharge = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const updateSurcharge = (id, data) =>
  Api.patch({
    url: `${URL}/${id}`,
    data,
  });

export const deleteSurcharge = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });
