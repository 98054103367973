export const getStorage = (key, isObject = false, storage = localStorage) => {
  if (key in storage) {
    return isObject ? JSON.parse(storage.getItem(key)) : storage.getItem(key);
  }
};

export const persistStorage = (
  key,
  value,
  isObject = false,
  storage = localStorage,
) => {
  isObject
    ? storage.setItem(key, JSON.stringify(value))
    : storage.setItem(key, value);
};

export const removeItemStorage = (key, storage = localStorage) => {
  if (key in storage) {
    storage.removeItem(key);
  } else {
    throw new Error("Данный ключ не найден в storage");
  }
};

export const clearStorage = (storage = localStorage) => {
  storage.clear();
};
