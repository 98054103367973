import Api from "src/shared/api/axiosWrapper";

const URL = "employees";

export const createEmployee = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const getEmployees = (params) =>
  Api.get({
    url: `${URL}`,
    params,
  });

export const updateEmployee = (id, data) =>
  Api.put({
    url: `${URL}/${id}`,
    data,
  });

export const deleteEmployee = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });
