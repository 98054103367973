import { useState, useEffect } from "react";

import { observer } from "mobx-react";
import moment from "moment";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Select from "react-select";

import "react-bootstrap-typeahead/css/Typeahead.css";

import { CustomButton } from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./BetsAddEditModal.module.css";

export const BetsAddEditModal = observer((props) => {
  const { show, handleClose, bet } = props;
  const { accrualsBetsStore, departamentStore, employeesStore } =
    useRootStore();

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [betDate, setBetDate] = useState("");
  const [betsNum, setBetsNum] = useState("");
  const [betsPrice, setBetsPrice] = useState("");
  const [betTotal, setBetTotal] = useState("");
  const [betNote, setBetNote] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formatNumber = (value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const removeSpaces = (value) => String(value).replace(/\s/g, "");

  useEffect(() => {
    if (bet) {
      setSelectedDepartment(bet.department.id);
      setSelectedEmployee(bet.employee.fullname);
      setBetDate(bet.bets_day);
      setBetsNum(bet.bets_num);
      setBetsPrice(bet.bets_price);
      setBetNote(bet.comment);
    } else {
      const today = moment().format("YYYY-MM-DD");
      setSelectedDepartment("");
      setSelectedEmployee(null);
      setBetDate(today);
      setBetsNum("");
      setBetsPrice("");
      setBetNote("");
      setErrorMessage("");
    }
  }, [bet, show]);

  useEffect(() => {
    const num = parseFloat(removeSpaces(betsNum));
    const price = parseFloat(removeSpaces(betsPrice));
    if (!Number.isNaN(num) && !Number.isNaN(price)) {
      setBetTotal(formatNumber((num * price).toFixed(2)));
    } else {
      setBetTotal("");
    }
  }, [betsNum, betsPrice]);

  useEffect(() => {
    departamentStore.loadDepartaments();
    employeesStore.loadEmployees();
  }, [departamentStore, employeesStore]);

  useEffect(() => {
    if (selectedDepartment) {
      const employees =
        employeesStore.getEmployeesByDepartment(selectedDepartment);
      setFilteredEmployees(employees);
    } else {
      setFilteredEmployees([]);
    }
  }, [selectedDepartment, employeesStore]);

  useEffect(() => {
    if (selectedEmployee) {
      const employee = filteredEmployees.find(
        (emp) => emp.id === selectedEmployee.value,
      );
      if (employee) {
        setBetsNum(employee.regular_bets_num || 0);
        setBetsPrice(employee.regular_bets_price || 0);
      } else {
        setBetsNum("");
        setBetsPrice("");
      }
    }
  }, [selectedEmployee, filteredEmployees]);

  const handleSave = async () => {
    setFormSubmitted(true);

    if (!selectedDepartment) {
      setErrorMessage("Пожалуйста выберите отдел.");
      return;
    }
    if (!selectedEmployee) {
      setErrorMessage("Пожалуйста выберите сотрудника.");
      return;
    }
    if (!betDate) {
      setErrorMessage("Пожалуйста выберите дату ставки.");
      return;
    }

    const betsNumValue = parseFloat(removeSpaces(betsNum));
    const betsPriceValue = parseFloat(removeSpaces(betsPrice));

    if (betsNumValue < 0 || betsPriceValue < 0) {
      setErrorMessage("Пожалуйста введите корректные данные.");
      return;
    }

    const betData = {
      department_id: selectedDepartment,
      employee_id: selectedEmployee.id,
      bet_date: betDate,
      bets_num: betsNumValue,
      bets_price: betsPriceValue,
      amount: parseFloat(removeSpaces(betTotal)),
      comment: betNote,
    };

    try {
      if (bet) {
        await accrualsBetsStore.updateBet(bet.id, betData);
      } else {
        await accrualsBetsStore.addBet(betData);
      }
      accrualsBetsStore.loadBets();
      handleClose();
    } catch (error) {
      setErrorMessage("Произошла ошибка при сохранении ставки.");
    }
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption.value);
    setSelectedEmployee(null);
    employeesStore.setSelectedDepartments([selectedOption.value]);
    employeesStore.loadEmployees();
  };

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
  };

  const handleNumberInputChange = (setter) => (e) => {
    const value = e.target.value.replace(/\s/g, "");
    if (value === "" || /^(0|[1-9]\d*)(\.\d*)?$/.test(value)) {
      setter(formatNumber(value));
    }
  };

  const departments = departamentStore.departaments.map((dept) => ({
    value: dept.id,
    label: dept.title,
  }));

  const employees = filteredEmployees.map((emp) => ({
    value: emp.id,
    label: emp.fullname,
  }));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {bet ? "Редактирование ставки" : "Создание ставки"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage("")}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        <Form>
          <Form.Group controlId="formDepartment">
            <Form.Label>Отдел</Form.Label>
            <Select
              maxMenuHeight={200}
              value={departments.find(
                (dept) => dept.value === selectedDepartment,
              )}
              onChange={handleDepartmentChange}
              options={departments}
              placeholder="Выберите отдел"
            />
          </Form.Group>
          <Form.Group controlId="formEmployee" className={style.mt_10}>
            <Form.Label>Сотрудник</Form.Label>
            <Typeahead
              id="employee-search"
              labelKey="label"
              onInputChange={(query) => {
                employeesStore.setSearchTerm(query);
                if (selectedDepartment) {
                  employeesStore.loadEmployees();
                }
              }}
              onChange={(selected) => {
                if (selected.length > 0) {
                  handleEmployeeChange(selected[0]);
                } else {
                  setSelectedEmployee(null);
                }
              }}
              options={employeesStore.employees.map((emp) => ({
                id: emp.id,
                label: emp.fullname,
              }))}
              placeholder="Введите имя сотрудника..."
              selected={selectedEmployee ? [selectedEmployee] : []}
              isDisabled={!selectedDepartment}
            />
          </Form.Group>
          <Form.Group controlId="formDate">
            <Form.Label className={style.mt_10}>Дата ставки</Form.Label>
            <Form.Control
              type="date"
              value={betDate}
              onChange={(e) => setBetDate(e.target.value)}
              onClick={(e) => e.target.showPicker()}
            />
          </Form.Group>
          <Form.Group controlId="formNum">
            <Form.Label className={style.mt_10}>Кол-во ставок</Form.Label>
            <Form.Control
              type="text"
              value={betsNum}
              onChange={handleNumberInputChange(setBetsNum)}
              placeholder="Введите количество ставок"
            />
          </Form.Group>
          <Form.Group controlId="formPrice">
            <Form.Label className={style.mt_10}>Цена ставки</Form.Label>
            <Form.Control
              type="text"
              value={betsPrice}
              onChange={handleNumberInputChange(setBetsPrice)}
              placeholder="Введите цену ставки"
            />
          </Form.Group>
          <Form.Group controlId="formTotal">
            <Form.Label className={style.mt_10}>
              Итого сумма за ставки
            </Form.Label>
            <Form.Control type="text" value={betTotal} readOnly />
          </Form.Group>
          <Form.Group controlId="formNote">
            <Form.Label className={style.mt_10}>Примечание</Form.Label>
            <Form.Control
              as="textarea"
              value={betNote}
              onChange={(e) => setBetNote(e.target.value)}
              placeholder="Введите примечание"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={style.footer}>
        <Button variant="secondary" onClick={handleClose}>
          Закрыть
        </Button>
        <CustomButton onClick={handleSave}>Сохранить</CustomButton>
      </Modal.Footer>
    </Modal>
  );
});
