import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Table } from "react-bootstrap";

import { ConfirmationModal, useConfirmModal, DeleteButton } from "src/shared";
import style from "src/shared/components/vacationsTable/VacationsTable.module.css";
import { useRootStore } from "src/shared/stores/initStore";

import ErrorModal from "../../ErrorVacationsModal/ErrorModal";
import {
  VacationAddEditModal,
  useVacationsModal,
} from "../../VacationAddEditModal";
import { deleteRow } from "../lib";

const VacationsContent = observer(() => {
  const { vacationsStore } = useRootStore();
  const { isShow, handleOpen, handleClose } = useVacationsModal();
  const [vacationToDelete, setVacationToDelete] = useState(null);
  const [currentVacationEdit, setCurrentVacationEdit] = useState(null);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const { closeConfirmModal, isConfirmModal, showConfirmModal } =
    useConfirmModal();

  useEffect(() => {
    if (vacationsStore.errorDelete) {
      setIsErrorModalVisible(true);
    }
  }, [vacationsStore.errorDelete]);

  const handleEdit = (vacation) => {
    setCurrentVacationEdit(vacation);
    handleOpen();
  };

  const promptDelete = (vacation) => {
    if (vacation && vacation.employee.fullname) {
      showConfirmModal();
      setVacationToDelete(vacation);
    }
  };

  const handleErrorModalClose = () => {
    setIsErrorModalVisible(false);
    vacationsStore.clearErrorDelete();
    vacationsStore.clearError();
  };

  return (
    <>
      <Table striped bordered hover>
        <tbody>
          {vacationsStore.vacations.map((vacation) => (
            <tr key={vacation.id} onClick={() => handleEdit(vacation)}>
              <td className={style.tableTitle}>
                <span>{vacation.employee.fullname}</span>
                <span>{vacation.employee.nickname || "-"}</span>
                <span>{vacation.department.title}</span>
                <span>{vacation.begin_date}</span>
                <span>{vacation.end_date}</span>
                <span>{vacation.total_days}</span>
                <span>{vacation.vacation_type || "-"}</span>
                <span>
                  <DeleteButton handleDelete={() => promptDelete(vacation)} />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <VacationAddEditModal
        show={isShow}
        isEdit={currentVacationEdit != null}
        vacation={currentVacationEdit}
        handleClose={handleClose}
      />

      {vacationToDelete && (
        <ConfirmationModal
          show={isConfirmModal}
          onHide={closeConfirmModal}
          title="Удаление отпуска"
          onConfirm={() =>
            deleteRow(vacationToDelete, vacationsStore, closeConfirmModal)
          }
        >
          Вы действительно хотите удалить отпуска для сотрудника{" "}
          {vacationToDelete?.employee?.fullname}
        </ConfirmationModal>
      )}

      <ErrorModal
        show={isErrorModalVisible}
        onHide={handleErrorModalClose}
        errorMessage={vacationsStore.errorDelete}
      />
    </>
  );
});

export default VacationsContent;
