import { DepartamentView } from "src/components";
import { Header } from "src/shared/ui/pageTitle";
import { PageWrapper } from "src/shared/ui/pageWrapper";

export function Departament() {
  return (
    <PageWrapper>
      <Header text="Отделы" />
      <DepartamentView />
    </PageWrapper>
  );
}
