import { useEffect } from "react";

import { observer } from "mobx-react";
import { Navigate, Outlet } from "react-router-dom";

import { useRootStore } from "src/shared/stores/initStore";

export const GuestRoute = observer((props) => {
  const { authStore } = useRootStore();

  return authStore.isAuth ? <Navigate to="/home" /> : props.children;
});

export const PrivateRoute = observer(() => {
  const { authStore } = useRootStore();

  useEffect(() => {
    if (authStore.isAuth) {
      authStore.getUser();
    }
  }, [authStore]);

  return authStore.isAuth ? <Outlet /> : <Navigate to="/login" />;
});
