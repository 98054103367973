import { useEffect } from "react";

const useEmployeeEffect = (
  employeesStore,
  employmentFormsStore,
  paymentFormsStore,
  departamentStore,
  userRole,
  authStore,
) => {
  useEffect(() => {
    document.title = "Сотрудники - Physical";
    departamentStore.loadDepartaments().then(() => {
      const availableDepartments = departamentStore.departaments.filter(
        (dept) => dept.headId === authStore.userData?.profile?.userId,
      );

      if (userRole === "department_head" && availableDepartments.length > 0) {
        const departmentIds = availableDepartments.map((dept) => dept.id);
        employeesStore.setSelectedDepartments(departmentIds);
        employeesStore.loadEmployees(departmentIds);
      } else {
        employeesStore.loadEmployees();
        employmentFormsStore.loadEmployments();
        paymentFormsStore.loadForms();
      }
    });
  }, [
    employeesStore,
    employmentFormsStore,
    paymentFormsStore,
    departamentStore,
    userRole,
    authStore.userData,
  ]);
};

export default useEmployeeEffect;
