export const thBetsArray = [
  "ФИО",
  "Отдел",
  "Должность",
  "Кол-во ставок",
  "Цена ставки",
  "Итого сумма за ставки",
  "Примечание",
  " ",
];
