import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Table } from "react-bootstrap";

import { icTrash } from "src/assets/icons/icons";
import style from "src/shared/components/Tables/paymentForms/index.module.css";
import CustomButton from "src/shared/components/customButton/CustomButton";
import LoadBar from "src/shared/components/loaders/LoadBar";
import PaginationPayment from "src/shared/components/paginations/PaginationComponent";
import { useSetTitle } from "src/shared/hooks";
import { useRootStore } from "src/shared/stores/initStore";
import ConfirmationModal from "src/shared/ui/modals/modalConfirm/ConfirmationModal";
import PaymentAddForm from "src/shared/ui/modals/paymentForms/PaymentAddForm";
import PaymentUpdateForm from "src/shared/ui/modals/paymentForms/PaymentUpdateForm";
import { PageWrapper } from "src/shared/ui/pageWrapper";

export const PaymentForms = observer(() => {
  useSetTitle("Формы выплат - Physical");
  const { paymentFormsStore } = useRootStore();
  // ДОБАВЛЕНИЕ
  const [showAddFormModal, setShowAddFormModal] = useState(false);
  // УДАЛЕНИЕ
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentFormId, setCurrentFormId] = useState(null);
  const [currentFormTitle, setCurrentFormTitle] = useState("");
  // РЕДАКТИРОВАНИЕ
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFormId, setEditFormId] = useState(null);
  const [editFormTitle, setEditFormTitle] = useState("");
  const [editFormType, setEditFormType] = useState(null);
  // ПАГИНАЦИЯ
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = paymentFormsStore.forms.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  // УДАЛЕНИЕ
  const handleDelete = (e, id, title) => {
    e.stopPropagation();
    setCurrentFormId(id);
    setCurrentFormTitle(title);
    setShowDeleteModal(true);
  };
  const confirmDelete = () => {
    paymentFormsStore.deleteForm(currentFormId);
    setShowDeleteModal(false);
  };

  // ДОБАВЛЕНИЕ
  const handleCloseModal = () => setShowAddFormModal(false);
  const handleAddNewForm = () => {
    paymentFormsStore.clearError();
    setShowAddFormModal(true);
  };

  const handleSave = ({ title, act_type }) => {
    paymentFormsStore.addForm({ title, act_type }).then(() => {
      if (!paymentFormsStore.error) {
        setShowAddFormModal(false);
        paymentFormsStore.clearError();
        paymentFormsStore.loadForms();
      }
    });
  };

  // РЕДАКТИРОВАНИЕ
  const handleEdit = (id, title, act_type) => {
    setEditFormId(id);
    setEditFormTitle(title);
    paymentFormsStore.clearError();
    setEditFormType(act_type);
    setShowEditModal(true);
  };
  const handleUpdate = (id, { title, act_type }) => {
    const titleTrimmed = typeof title === "string" ? title.trim() : "";

    if (titleTrimmed) {
      paymentFormsStore
        .updateForm(id, { title: titleTrimmed, act_type })
        .then(() => {
          if (!paymentFormsStore.error) {
            setShowEditModal(false);
            paymentFormsStore.loadForms();
          }
        });
    } else {
      paymentFormsStore.setError("Название формы не может быть пустым.");
    }
  };

  useEffect(() => {
    paymentFormsStore.loadForms();
    paymentFormsStore.loadActTypes();
  }, [paymentFormsStore]);

  return (
    <PageWrapper>
      <h1 className={style.headerTitle}>Формы выплат</h1>

      <CustomButton
        onClick={handleAddNewForm}
        disabled={false}
        style={{ margin: "20px 0" }}
      >
        Новая форма выплат
      </CustomButton>

      <div className={style.tableScrollWrapper}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className={style.tableHead}>
                <span>Название</span>
                <span>Тип алгоритма</span>
                <span> </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {paymentFormsStore.isLoading ? (
              <tr>
                <td colSpan="1">
                  <span className={style.spinner}>
                    <LoadBar />
                  </span>
                </td>
              </tr>
            ) : (
              currentItems.map((form) => (
                <tr key={form.id}>
                  <td
                    className={style.tableTitle}
                    onClick={() =>
                      handleEdit(form.id, form.title, form.act_type)
                    }
                  >
                    <span>{form.title}</span>
                    <span>{form.act_type || "-"}</span>
                    <span>
                      {" "}
                      <button
                        type="button"
                        className={style.buttonBackground}
                        onClick={(e) => handleDelete(e, form.id, form.title)}
                      >
                        <img
                          className={style.iconTrash}
                          src={icTrash}
                          alt="delete_item"
                        />
                      </button>
                    </span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>

      <PaymentAddForm
        show={showAddFormModal}
        onHide={handleCloseModal}
        handleSave={handleSave}
        error={paymentFormsStore.error}
      />

      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
        title="Подтверждение удаления"
      >
        Вы действительно хотите удалить {currentFormTitle}
      </ConfirmationModal>

      <PaymentUpdateForm
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        initialTitle={editFormTitle}
        onUpdate={handleUpdate}
        formId={editFormId}
        initialType={editFormType}
        error={paymentFormsStore.error}
      />

      {paymentFormsStore.isLoading ? null : (
        <PaginationPayment
          itemsPerPage={itemsPerPage}
          totalItems={paymentFormsStore.forms.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </PageWrapper>
  );
});
