import axios from "axios"; 

import Api from "src/shared/api/Api";
import { postRefresh } from "src/shared/api/auth/refresh.api";

const baseURL = process.env.REACT_APP_BASE_URL;

/* for manual abort of request  */
const createCancelToken = () => axios.CancelToken.source();

export const sourceObject = {
  source: createCancelToken(),
};

const instanceOfAxios = axios.create({
  baseURL,
});

instanceOfAxios.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && newConfig.url !== "auth/v2/refresh") {
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

instanceOfAxios.interceptors.response.use(
  (response) => {
    if (response.status !== 200) {
      console.error("Status:", response.status);
    }

    sourceObject.source = createCancelToken();

    return response;
  },

  async (error) => {
    const originalRequest = error.config;
    const isAuthRequest = originalRequest.url.includes("/login");

    if (axios.isCancel(error)) {
      sourceObject.source = createCancelToken();
      return Promise.resolve();
    }

    if (error.response.status === 401) {
      if (!originalRequest.retry) {
        originalRequest.retry = true;
        if (isAuthRequest) {
          return Promise.reject(error);
        }
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        if (accessToken && refreshToken) {
          try {
            const res = await postRefresh(refreshToken, accessToken);
            localStorage.setItem("accessToken", res.data.access_token);
            localStorage.setItem("refreshToken", res.data.refresh_token);
            originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;
            return instanceOfAxios(originalRequest);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        }
      }
    }

    // if (!isAuthRequest) window.location.href = "/login";

    return Promise.reject(error);
  },
);

export default new Api(instanceOfAxios);
