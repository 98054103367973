import { makeAutoObservable } from "mobx";

import {
  addDepartament,
  getDepartaments,
  updateDepartament,
  deleteDepartament,
  addDepEmployees,
  deleteDepEmployyes,
  getDepartmentById,
} from "src/shared/api";

export const DepartamentStore = () => {
  const store = {
    departaments: [],
    isLoading: false,
    error: null,
    currentPage: 1,
    pageSize: 20,
    searchTerm: "",
    departmentHeadIds: [],
    totalDepartments: 0,

    setDepartaments(data) {
      if (Array.isArray(data)) {
        this.departaments = data;
      } else {
        console.error(
          "Failed to set departaments, data is not an array:",
          data,
        );
      }
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    setTotalDepartments(total) {
      this.totalDepartments = total;
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    setPageSize(size) {
      this.pageSize = size;
    },

    setSearchTerm(term) {
      this.searchTerm = term;
      this.loadDepartaments();
    },

    setDepartmentHeadIds(ids) {
      this.departmentHeadIds = ids;
    },

    resetFilters() {
      this.setSearchTerm("");
      this.setDepartmentHeadIds([]);
      this.setCurrentPage(1);
      this.loadDepartaments();
    },

    async loadDepartaments() {
      try {
        this.setIsLoading(true);

        const params = new URLSearchParams({
          q: this.searchTerm,
          page: this.currentPage,
          limit: this.pageSize,
        });

        if (this.departmentHeadIds.length > 0) {
          this.departmentHeadIds.forEach((id) => {
            params.append("department_head_ids", id);
          });
        }

        const response = await getDepartaments(params);
        if (response.data) {
          this.setTotalDepartments(response.data.total);
          this.setDepartaments(response.data.data);
          this.clearError();
        }
      } catch (error) {
        this.setError("Ошибка при загрузке отделов");
      } finally {
        this.setIsLoading(false);
      }
    },

    async getDepartment(departmentId) {
      this.setIsLoading(true);
      try {
        const response = await getDepartmentById(departmentId);

        if (!response.data.employees) {
          response.data.employees = [];
        }
        this.setIsLoading(false);
        return response.data;
      } catch (error) {
        console.error("Failed to fetch department:", error);
        this.setError("Error loading department");
        this.setIsLoading(false);
        return null;
      }
    },

    async addDepartament(departamentData) {
      this.setIsLoading(true);
      try {
        const response = await addDepartament(departamentData);
        this.setDepartaments([...this.departaments, response.data]);
        this.setError(null);
      } catch (error) {
        this.setError("Ошибка при добавлении отдела");
      } finally {
        this.setIsLoading(false);
      }
    },

    async updateDepartament(id, departamentData) {
      this.setIsLoading(true);
      try {
        const response = await updateDepartament(id, departamentData);
        this.setDepartaments(
          this.departaments.map((departament) =>
            departament.id === id
              ? { ...departament, ...response.data }
              : departament,
          ),
        );
        this.setError(null);
        return response;
      } catch (error) {
        this.setError("Ошибка при обновлении отдела");
      } finally {
        this.setIsLoading(false);
      }
    },

    async deleteDepartament(id) {
      this.setIsLoading(true);
      try {
        await deleteDepartament(id);
        this.setDepartaments(
          this.departaments.filter((departament) => departament.id !== id),
        );
        this.setError(null);
      } catch (error) {
        this.setError("Ошибка при удалении отдела");
      } finally {
        this.setIsLoading(false);
      }
    },

    async addEmployeeToDepartament(departmentId, employeesIds) {
      try {
        const response = await addDepEmployees(departmentId, employeesIds);
        this.loadDepartaments();
        return response;
      } catch (error) {
        console.error("Ошибка при добавлении сотрудников:", error);
        return null;
      }
    },

    async removeEmployeeFromDepartament(departmentId, employeesIds) {
      try {
        await deleteDepEmployyes(departmentId, employeesIds);
        this.loadDepartaments();
      } catch (error) {
        this.setError("Ошибка при удалении сотрудника из отдела");
      }
    },
  };

  makeAutoObservable(store);
  return store;
};
