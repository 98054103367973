import { Table } from "react-bootstrap";

import style from "./ReusableTable.module.css";

export default function ReusableTable(props) {
  const { thArray, children } = props;
  return (
    <div className={style.tableContainer}>
      <div className={style.tableWrapper}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className={style.tableHead}>
                {thArray.map((item) => (
                  <span key={item}>{item}</span>
                ))}
              </th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </Table>
      </div>
    </div>
  );
}
