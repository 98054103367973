import { useEffect, useState } from "react";

import ru from "date-fns/locale/ru";
import { observer } from "mobx-react";
import { FormControl, Stack } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  CustomButton,
  DepartmentFilterDropdown,
  PaginationComponent,
  useDebounce,
  useSetTitle,
  useSizePageDepartment,
  VacationsTable,
} from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";

import {
  useVacationsModal,
  VacationAddEditModal,
} from "./VacationAddEditModal";
import style from "./Vacations.module.css";
import VacationsContent from "./VacationsContent/ui/VacationsContent";
import { thVacationsArray } from "../lib";

export const Vacations = observer(() => {
  useSetTitle("Отпуска");
  useSizePageDepartment();
  registerLocale("ru", ru);
  const { vacationsStore, departamentStore } = useRootStore();
  const { isShow, handleOpen, handleClose } = useVacationsModal();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const toggleFilters = () => setFiltersVisible((prev) => !prev);

  const [startDate, setStartDate] = useState(
    vacationsStore.selectedDateRange.start,
  );
  const [endDate, setEndDate] = useState(vacationsStore.selectedDateRange.end);
  useEffect(() => {
    vacationsStore.setSelectedDepartments([]);
    vacationsStore.loadVacations();
    vacationsStore.loadVacationTypes();
  }, [vacationsStore]);

  useEffect(() => {
    vacationsStore.setSearchTerm(debouncedSearchTerm);
    vacationsStore.loadVacations();
  }, [debouncedSearchTerm, vacationsStore]);

  useEffect(() => {
    setStartDate(vacationsStore.selectedDateRange.start);
    setEndDate(vacationsStore.selectedDateRange.end);
  }, [
    vacationsStore.selectedDateRange.start,
    vacationsStore.selectedDateRange.end,
  ]);

  const handleDepartmentToggle = (deptId) => {
    const newSelectedDepartments = vacationsStore.selectedDepartments.includes(
      deptId,
    )
      ? vacationsStore.selectedDepartments.filter((id) => id !== deptId)
      : [...vacationsStore.selectedDepartments, deptId];

    vacationsStore.setSelectedDepartments(newSelectedDepartments);
    vacationsStore.loadVacations();
  };

  const handleDepartmentReset = () => {
    vacationsStore.setSelectedDepartments([]);
    vacationsStore.loadVacations();
  };

  const handlePageChange = (page) => {
    vacationsStore.setCurrentPage(page);
    vacationsStore.loadVacations();
  };

  const handlePageSizeChange = (size) => {
    vacationsStore.setPageSize(size);
    vacationsStore.setCurrentPage(1);
    vacationsStore.loadVacations();
  };

  const handleDateRangeChange = ([start, end]) => {
    setStartDate(start);
    setEndDate(end);
    vacationsStore.setSelectedDateRange({ start, end });
    vacationsStore.loadVacations();
  };

  const handleResetFilters = () => {
    vacationsStore.resetFilters();
  };

  return (
    <>
      <Stack gap={3}>
        <div className={style.buttonsContainer}>
          <div className={style.buttons}>
            <CustomButton onClick={handleOpen}>Новый отпуск</CustomButton>
            <CustomButton onClick={toggleFilters}>
              {filtersVisible ? "Скрыть фильтры" : "Показать фильтры"}
            </CustomButton>
          </div>

          {filtersVisible && (
            <div className={style.filters}>
              <DepartmentFilterDropdown
                selectedDepartments={vacationsStore.selectedDepartments}
                onToggleDepartment={handleDepartmentToggle}
                onResetDepartments={handleDepartmentReset}
              />
              <div className={style.datePickerContainer}>
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale="ru"
                  className={style.datePicker}
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleDateRangeChange}
                  withPortal
                  // todayButton='today'
                  // isClearable
                  placeholderText="Выберите даты"
                  dateFormat="dd.MM.yyyy"
                  minDate={new Date(2020, 0, 1)}
                  maxDate={new Date(2035, 11, 31)}
                />
              </div>
              {/* <div className={style.rigthGroup}> */}
              <FormControl
                className={style.searchInput}
                type="text"
                placeholder="Поиск"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {/* </div> */}
              <CustomButton onClick={handleResetFilters}>
                Сбросить фильтры
              </CustomButton>
            </div>
          )}
        </div>
        <VacationsTable thArray={thVacationsArray}>
          <VacationsContent />
        </VacationsTable>

        <PaginationComponent
          itemsPerPage={vacationsStore.pageSize}
          totalItems={vacationsStore.totalVacations}
          paginate={handlePageChange}
          currentPage={vacationsStore.currentPage}
          onPageSizeChange={handlePageSizeChange}
        />
      </Stack>

      <VacationAddEditModal
        show={isShow}
        handleClose={handleClose}
        vacation={null}
      />
    </>
  );
});
