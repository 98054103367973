import NavigationRouter from "src/App/router/NavigationRouter";
import { StoreProvider } from "src/shared/stores/initStore";

function App() {
  return (
    <StoreProvider>
      <NavigationRouter />
    </StoreProvider>
  );
}

export default App;
