import { Suspense, lazy, useEffect, useState } from "react";

import { observer } from "mobx-react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import { GuestRoute, PrivateRoute } from "src/App/router/Guard";
import ProtectedRoutes from "src/App/router/ProtectedRoutes";
import { DepartamentCreate, DepartamentEdit } from "src/components";
import Home from "src/pages/Home/Home";
import {
  AllAccrualsPageLazy,
  AllEmployeeAccrualsPageLazy,
} from "src/pages/accruals/allAccruals";
import { AccrualsBetsPageLazy } from "src/pages/accruals/bets";
import { AccrualsDealsPageLazy } from "src/pages/accruals/deals";
import { AccrualsReportsPageLazy } from "src/pages/accruals/reports";
import { Auth } from "src/pages/auth/Auth";
import { Departament } from "src/pages/departament";
import DepartmentHead from "src/pages/departmentHead/DepartmentHead";
import { Employees } from "src/pages/employees/Employees";
import { UserManagment } from "src/pages/userManagment/UserManagment";
import { VacationsPageLazy } from 'src/pages/vacations/VacationsPage.lazy';
import { EmploymentForms } from "src/shared/components/Tables/employmentForms/EmploymentForms";
import { PaymentForms } from "src/shared/components/Tables/paymentForms/PaymentForms";
import Loader from "src/shared/components/loaders/Loader";
import Spinner from "src/shared/components/loaders/Spinner";
import style from "src/shared/components/loaders/index.module.css";
import { useRootStore } from "src/shared/stores/initStore";

const ExtraPayments = lazy(
  () => import("src/pages/ExtraPayments/ExtraPayments"),
);

const NavigationRouter = observer(() => {
  const { authStore } = useRootStore();
  const userRole = authStore.userData?.profile?.role;
  const superUser = authStore.userData?.superuser;
  const [isAuthReady, setIsAuthReady] = useState(false);

  useEffect(() => {
    authStore.getUser().finally(() => setIsAuthReady(true));
  }, [authStore]);

  if (!isAuthReady) {
    return (
      <div className={style.centerWievPort}>
        <Spinner />
      </div>
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <GuestRoute>
                <Auth />
              </GuestRoute>
            }
          />
          <Route element={<PrivateRoute />}>
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<Home />} />

              {userRole === "department_head" && (
                <>
                  <Route path="departmentHead" element={<DepartmentHead />} />
                  <Route path="employmentForms" element={<EmploymentForms />} />
                  <Route path="employees" element={<Employees />} />
                  <Route path="departament" element={<Departament />} />
                  <Route
                    path="accruals/extraPayments"
                    element={<ExtraPayments />}
                  />
                  <Route
                    path="accruals/deals"
                    element={<AccrualsDealsPageLazy />}
                  />
                  <Route
                    path="accruals/bets"
                    element={<AccrualsBetsPageLazy />}
                  />
                  <Route
                    path="accruals/allAccrulas"
                    element={<AllAccrualsPageLazy />}
                  />
                  <Route
                    path="accruals/employee/:employeeId"
                    element={<AllEmployeeAccrualsPageLazy />}
                  />
                  <Route
                    path="accruals/reports"
                    element={<AccrualsReportsPageLazy />}
                  />
                  <Route
                    path="vacations"
                    element={<VacationsPageLazy />}
                  />
                </>
              )}

              {superUser && (
                <Route path="usersManagment" element={<UserManagment />} />
              )}

              {userRole === "accountant" && (
                <>
                  <Route path="paymentForms" element={<PaymentForms />} />
                  <Route path="employmentForms" element={<EmploymentForms />} />
                  <Route path="employees" element={<Employees />} />
                  <Route
                    path="accruals/deals"
                    element={<AccrualsDealsPageLazy />}
                  />
                  <Route path="departament" element={<Departament />} />
                  <Route
                    path="departament/createDepartament"
                    element={<DepartamentCreate />}
                  />
                  <Route
                    path="/departments/:departmentId/edit"
                    element={<DepartamentEdit />}
                  />
                  <Route
                    path="accruals/reports"
                    element={<AccrualsReportsPageLazy />}
                  />
                  <Route
                    path="accruals/allAccrulas"
                    element={<AllAccrualsPageLazy />}
                  />
                  <Route
                    path="accruals/employee/:employeeId"
                    element={<AllEmployeeAccrualsPageLazy />}
                  />
                </>
              )}
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </Suspense>
  );
});

export default NavigationRouter;
