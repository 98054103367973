import Api from "src/shared/api/axiosWrapper";

const URL = "deal_charges";

export const getDeals = (params) =>
  Api.get({
    url: `${URL}`,
    params,
  });

export const createDeals = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const updateDeals = (id, data) =>
  Api.patch({
    url: `${URL}/${id}`,
    data,
  });

export const deleteDeals = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });

export const getUnitsOfMeasurement = (query) =>
  Api.get({
    url: `${URL}/units?q=${query}`,
  });
