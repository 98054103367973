import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import {
  Table,
  FormControl,
  InputGroup,
  Dropdown,
  FormCheck,
} from "react-bootstrap";

import { icTrash } from "src/assets";
import { useEmployeeEffect } from "src/components";
import style from "src/pages/employees/index.module.css";
import {
  DepartmentFilterDropdown,
  CustomButton,
  PaginationComponent,
  useDebounce,
  PageWrapper,
  Header,
  AddEmployee,
  EditEmployee,
  useSizePageDepartment,
} from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";
import ConfirmationModal from "src/shared/ui/modals/modalConfirm/ConfirmationModal";

export const Employees = observer(() => {
  const {
    employeesStore,
    employmentFormsStore,
    paymentFormsStore,
    departamentStore,
    authStore,
  } = useRootStore();
  const userRole = authStore.userData?.profile?.role;
  const isDepartmentHead = userRole === "department_head";

  // ДОБАВЛЕНИЕ
  const [showAddModal, setShowAddModal] = useState(false);
  // РЕДАКТИРОВАНИЕ
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  // УДАЛЕНИЕ
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentFormId, setCurrentFormId] = useState(null);
  const [currentFormTitle, setCurrentFormTitle] = useState("");
  // ПОИСК / ФИЛЬТРАЦИЯ
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const toggleFilters = () => setFiltersVisible((prev) => !prev);

  // ЗАГРУЗКА форм + НЕЙМ СТРАНИЦЫ
  useSizePageDepartment();
  useEmployeeEffect(
    employeesStore,
    employmentFormsStore,
    paymentFormsStore,
    departamentStore,
    userRole,
    authStore,
  );

  useEffect(() => {
    employeesStore.setSearchTerm(debouncedSearchTerm);
    employeesStore.loadEmployees();
  }, [debouncedSearchTerm, employeesStore]);

  // ДОБАВЛЕНИЕ
  const handleAddEmployee = (userData) => {
    employeesStore.addEmployee(userData).then(() => {
      if (!employeesStore.error) {
        setShowAddModal(false);
        employeesStore.loadEmployees();
      }
    });
  };

  // РЕДАКТИРОВАНИЕ
  const handleEditEmployee = (userData) => {
    employeesStore.updateEmployee(currentEmployee.id, userData).then(() => {
      setShowEditModal(false);
      employeesStore.loadEmployees();
    });
  };
  const handleOpenEditModal = (employee) => {
    setCurrentEmployee(employee);
    setShowEditModal(true);
  };

  // УДАЛЕНИЕ
  const handleDelete = (e, id, title) => {
    e.stopPropagation();
    setCurrentFormId(id);
    setCurrentFormTitle(title);
    setShowDeleteModal(true);
  };
  const confirmDelete = () => {
    employeesStore.deleteEmployee(currentFormId).then(() => {
      setShowDeleteModal(false);
      employeesStore.loadEmployees();
    });
  };

  const resetFilters = () => {
    employeesStore.resetFilters();
    setSearchTerm("");
  };

  const handleDepartmentToggle = (deptId) => {
    const newSelectedDepartments = employeesStore.selectedDepartments.includes(
      deptId,
    )
      ? employeesStore.selectedDepartments.filter((id) => id !== deptId)
      : [...employeesStore.selectedDepartments, deptId];

    employeesStore.setSelectedDepartments(newSelectedDepartments);
    employeesStore.loadEmployees();
  };

  const handleDepartmentReset = () => {
    employeesStore.setSelectedDepartments([]);
    employeesStore.loadEmployees();
  };

  const handlePaymentFormSelect = (formId) => {
    employeesStore.setSelectedPaymentForm(formId);
    employeesStore.loadEmployees();
  };

  const handleFiredOnlyToggle = () => {
    employeesStore.setFiredOnly(!employeesStore.firedOnly);
    employeesStore.loadEmployees();
  };

  const handleEmploymentFormSelect = (formId) => {
    employeesStore.setSelectedEmploymentForm(formId);
    employeesStore.loadEmployees();
  };

  const handlePageChange = (page) => {
    employeesStore.setCurrentPage(page);
    employeesStore.loadEmployees();
  };

  const handlePageSizeChange = (size) => {
    employeesStore.setPageSize(size);
    employeesStore.setCurrentPage(1);
    employeesStore.loadEmployees();
  };

  return (
    <PageWrapper isMoreWidth>
      <Header text="Сотрудники" />

      <div className={style.buttonsContainer}>
        <div className={style.buttons}>
          {!isDepartmentHead && (
            <CustomButton
              className={style.customButtonSize}
              onClick={() => setShowAddModal(true)}
              disabled={false}
            >
              Добавить сотрудника
            </CustomButton>
          )}
          <CustomButton onClick={toggleFilters}>
            {filtersVisible ? "Скрыть фильтры" : "Показать фильтры"}
          </CustomButton>
        </div>

        {filtersVisible && (
          <>
            <div className={style.filters}>
              <DepartmentFilterDropdown
                selectedDepartments={employeesStore.selectedDepartments}
                onToggleDepartment={handleDepartmentToggle}
                onResetDepartments={handleDepartmentReset}
              />

              {!isDepartmentHead && (
                <Dropdown onSelect={handlePaymentFormSelect}>
                  <Dropdown.Toggle
                    id="dropdown-payment-form"
                    className={style.dropdownContainer}
                  >
                    {employeesStore.selectedPaymentForm
                      ? paymentFormsStore.forms.find(
                          (p) => p.id === employeesStore.selectedPaymentForm,
                        )?.title
                      : "Форма выплаты"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {paymentFormsStore.forms.map((form) => (
                      <Dropdown.Item key={form.id} eventKey={form.id}>
                        {form.title}
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Item eventKey="">Без фильтра</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {!isDepartmentHead && (
                <Dropdown onSelect={handleEmploymentFormSelect}>
                  <Dropdown.Toggle
                    id="dropdown-employment-form"
                    className={style.dropdownContainer}
                  >
                    {employeesStore.selectedEmploymentForm
                      ? employmentFormsStore.employments.find(
                          (e) => e.id === employeesStore.selectedEmploymentForm,
                        )?.title
                      : "Форма ТУ"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {employmentFormsStore.employments.map((form) => (
                      <Dropdown.Item key={form.id} eventKey={form.id}>
                        {form.title}
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Item eventKey="">Без фильтра</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <InputGroup className={style.searchInput}>
                <FormControl
                  className={style.searchInput}
                  type="text"
                  placeholder="Поиск"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <div className={style.rigthSide}>
                <CustomButton onClick={resetFilters}>
                  Сброс фильтров
                </CustomButton>
              </div>
            </div>
            <div
              className={style.checkboxField}
              role="button"
              tabIndex={0}
              onClick={handleFiredOnlyToggle}
              onKeyPress={(e) =>
                e.key === "Enter" || e.key === " "
                  ? handleFiredOnlyToggle()
                  : null
              }
            >
              <FormCheck
                type="checkbox"
                label="Показать только уволенных сотрудников"
                checked={employeesStore.firedOnly}
                onChange={handleFiredOnlyToggle}
                className={style.checkbox}
              />
            </div>
          </>
        )}
      </div>
      <div className={style.tableScrollWrapper}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className={style.tableHead}>
                <span>ФИО</span>
                <span>Никнейм</span>
                <span>Должность</span>
                <span>Отдел</span>
                <span>Форма ТУ</span>
                <span>{}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {employeesStore.employees.map((employee) => (
              <tr key={employee.id}>
                <td
                  className={style.tableTitle}
                  onClick={() => handleOpenEditModal(employee)}
                >
                  <span>{employee.fullname}</span>
                  <span>{employee?.nickname}</span>
                  <span>{employee?.job_title}</span>
                  <span>
                    {employee?.departments
                      ?.map((dept) => dept.title)
                      .join(", ")}
                  </span>
                  <span>{employee?.employment_form?.title}</span>
                  <button
                    type="button"
                    className={style.buttonBackground}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(e, employee.id, employee.fullname);
                    }}
                  >
                    <img
                      className={style.iconTrash}
                      src={icTrash}
                      alt="delete_item"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          itemsPerPage={employeesStore.pageSize}
          totalItems={employeesStore.total}
          paginate={handlePageChange}
          currentPage={employeesStore.currentPage}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>

      {showAddModal && (
        <AddEmployee
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
          onSave={handleAddEmployee}
        />
      )}

      {showEditModal && (
        <EditEmployee
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          onSave={handleEditEmployee}
          initialData={currentEmployee}
        />
      )}

      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
        title="Подтверждение удаления"
      >
        Вы действительно хотите удалить {currentFormTitle}?
      </ConfirmationModal>
    </PageWrapper>
  );
});
