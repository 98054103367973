import { makeAutoObservable } from "mobx";
import moment from "moment";

import {
  createReport,
  deleteReport,
  getReportStatuses,
  getReports,
  updateReport,
  downloadPayroll,
} from "src/shared/api";

export const ReportsStore = () => {
  const store = {
    reports: [],
    statuses: [],
    payrollData: [],
    isLoading: false,
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalReports: 0,
    selectedDepartment: "",
    selectedStatus: "",
    selectedMonth: new Date(),
    selectedAuthor: "",

    setReports(reports) {
      this.reports = reports || [];
    },

    setPayrollData(data) {
      this.payrollData = data || [];
    },

    setTotalReports(total) {
      this.totalReports = total;
    },

    setStatuses(statuses) {
      this.statuses = statuses || [];
    },

    setSelectedDepartment(department) {
      this.selectedDepartment = department;
    },

    setSelectedStatus(status) {
      this.selectedStatus = status;
    },

    setSelectedMonth(date) {
      this.selectedMonth = date;
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    setPageSize(size) {
      this.pageSize = size;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    setSelectedAuthor(authorId) {
      this.selectedAuthor = authorId;
    },

    resetFilters() {
      this.setSelectedDepartment();
      this.setSelectedStatus("");
      this.setSelectedMonth(new Date());
      this.setSelectedAuthor("");
      this.setCurrentPage(1);
      this.loadReports();
    },

    async loadReports() {
      try {
        this.setIsLoading(true);

        const params = new URLSearchParams({
          date: moment(this.selectedMonth).format("YYYY-MM-DD"),
          page: this.currentPage,
          limit: this.pageSize,
        });

        if (this.selectedDepartment) {
          params.append("department_id", this.selectedDepartment);
        }

        if (this.selectedStatus) {
          params.append("report_status", this.selectedStatus);
        }

        if (this.selectedAuthor) {
          params.append("author_id", this.selectedAuthor);
        }

        const res = await getReports(params);
        if (res.data) {
          this.setReports(res.data.data);
          this.setTotalReports(res.data.total);
          this.clearError();
        }
      } catch (error) {
        this.setError("Ошибка при загрузке данных о отчетах");
      } finally {
        this.setIsLoading(false);
      }
    },

    async loadPayroll() {
      try {
        this.setIsLoading(true);

        const params = new URLSearchParams({
          payment_form_id: this.selectedPaymentFormId,
          date: moment(this.selectedMonth).format("YYYY-MM-DD"),
        });

        if (this.selectedEmployeeIds && this.selectedEmployeeIds.length > 0) {
          this.selectedEmployeeIds.forEach((employeeId) => {
            params.append("employee_ids", employeeId);
          });
        }

        if (this.completeDays) {
          params.append("complete_days", this.completeDays);
        }

        await downloadPayroll(params);

        this.clearError();
      } catch (error) {
        this.setError("Ошибка при загрузке данных о зарплатах (payroll)");
      } finally {
        this.setIsLoading(false);
      }
    },

    async loadReportStatuses() {
      try {
        this.setIsLoading(true);
        const res = await getReportStatuses();
        if (res.data) {
          this.setStatuses(res.data.statuses);
          this.setError(null);
        }
      } catch (error) {
        this.setError("Ошибка при загрузке статусов отчетов");
      } finally {
        this.setIsLoading(false);
      }
    },

    async addReport(data) {
      this.setIsLoading(true);
      try {
        const res = await createReport(data);
        this.setReports([...this.reports]);
        this.setError(null);
      } catch (error) {
        this.setError("Ошибка при добавлении данных о отчете");
      } finally {
        this.setIsLoading(false);
      }
    },

    async updateReport(id, data) {
      this.setIsLoading(true);
      try {
        const res = await updateReport(id, data);
        if (res.data) {
          this.setReports(
            this.reports.map((report) =>
              report.id === id ? res.data : report,
            ),
          );
          this.clearError();
        }
        this.loadReports();
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(error.response.data.detail || "Закрыт для изменения");
        } else {
          this.setError("Ошибка при обновлении данных о отчете");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async deleteReport(id) {
      try {
        await deleteReport(id);
        this.setReports(this.reports.filter((report) => report.id !== id));
      } catch (error) {
        this.setError("Ошибка при удалении данных о отчете");
      }
    },
  };

  makeAutoObservable(store);
  return store;
};
