import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import moment from "moment";
import { Alert, Button, Dropdown, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { CustomButton } from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./ReportAddEditModal.module.css";

export const ReportAddModal = observer((props) => {
  const { show, handleClose, report, onSave } = props;
  const { departamentStore, reportsStore } = useRootStore();
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadDepartments() {
      await departamentStore.loadDepartaments();
    }
    loadDepartments();
  }, [departamentStore]);

  useEffect(() => {
    if (show) {
      if (report) {
        setSelectedDate(report.date || moment().format("YYYY-MM-DD"));
        setSelectedDepartment(
          departamentStore.departaments.find(
            (dept) => dept.id === report.department_id,
          ) || null,
        );
      } else {
        const currentYearMonth = moment().format("YYYY-MM");
        setSelectedDate(`${currentYearMonth}-01`);
        if (departamentStore.departaments.length === 1) {
          setSelectedDepartment(departamentStore.departaments[0]);
        } else {
          setSelectedDepartment(null);
        }
      }
      setError(null);
    }
  }, [show, report, departamentStore.departaments]);

  const handleSave = async () => {
    if (!selectedDate || !selectedDepartment) {
      setError("Пожалуйста, заполните все поля");
      return;
    }

    const reportData = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
      department_id: selectedDepartment.id,
    };

    try {
      if (report) {
        await reportsStore.updateReport(report.id, reportData);
      } else {
        await reportsStore.addReport(reportData);
      }
      handleClose();
      if (onSave) {
        onSave(new Date(selectedDate), selectedDepartment.id);
      }
      navigate("/accruals/reports", {
        state: {
          createdReportDate: new Date(selectedDate),
          departments: [selectedDepartment.id],
        },
      });
    } catch (err) {
      console.error("Ошибка при сохранении отчета:", err);
      setError(
        `Ошибка при сохранении отчета: ${err.response?.data?.detail || err.message}`,
      );
    }
  };

  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    if (error) setError(null);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {report ? "Редактировать Отчет" : "Создать Отчет"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group controlId="formReportDate">
            <Form.Label className={style.mt_10}>Дата отчета</Form.Label>
            <Form.Control
              type="month"
              value={moment(selectedDate).format("YYYY-MM")}
              onChange={(e) => setSelectedDate(new Date(moment(e.target.value, "YYYY-MM")))}
              onClick={(e) => e.target.showPicker()}
            />
          </Form.Group>

          <Form.Group controlId="formDepartmentSelect">
            <Form.Label className={style.mt_10}>Выберите отдел</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className={style.dropdownContainer}
              >
                {selectedDepartment
                  ? selectedDepartment.title
                  : "Выберите отдел"}
              </Dropdown.Toggle>

              <Dropdown.Menu className={style.scrollableDropdownMenu}>
                {departamentStore.departaments.map((dept) => (
                  <Dropdown.Item
                    key={dept.id}
                    onClick={() => handleDepartmentSelect(dept)}
                  >
                    {dept.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={style.footer}>
        <Button variant="secondary" onClick={handleClose}>
          Отмена
        </Button>
        <CustomButton onClick={handleSave}>Сохранить</CustomButton>
      </Modal.Footer>
    </Modal>
  );
});
