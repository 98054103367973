import { useEffect, useState } from "react";

import { Form, Button, Modal, Dropdown } from "react-bootstrap";

import { CustomButton } from "src/shared/components";
import { useRootStore } from "src/shared/stores/initStore";
import style from "src/shared/ui/modals/paymentForms/index.module.css";

export default function PaymentAddForm({ show, onHide, handleSave, error }) {
  const { paymentFormsStore } = useRootStore();
  const [title, setTitle] = useState("");
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    if (show) paymentFormsStore.loadActTypes();
  }, [show, paymentFormsStore]);

  useEffect(() => {
    if (show) {
      setTitle("");
      setSelectedType("");
      paymentFormsStore.clearError(); 
    }
  }, [show, paymentFormsStore]);

  const handleFormSubmit = () => {
    const payload = { title };
    if (selectedType) payload.act_type = selectedType;

    handleSave(payload); 
  };

  const handleTypeSelect = (type) => setSelectedType(type);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={style.modalContainer}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Создание новой формы выплаты</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formPaymentTitle">
            <Form.Label>Название формы выплаты</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите название"
              value={title}
              onChange={(e) => setTitle(e.target.value || "")}
              isInvalid={!!paymentFormsStore.error} 
            />
            {paymentFormsStore.error && (
              <Form.Control.Feedback type="invalid">
                {paymentFormsStore.error}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="formPaymentType" className={style.mt}>
            <Form.Label>Тип алгоритма формирования ведомостей</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className={style.dropdownContainer}
              >
                {selectedType || "Выберите тип алгоритма"}
              </Dropdown.Toggle>
              <Dropdown.Menu className={style.scrollableDropdownMenu}>
                {paymentFormsStore.actTypes.map((type) => (
                  <Dropdown.Item
                    key={type}
                    onClick={() => handleTypeSelect(type)}
                  >
                    {type}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleTypeSelect(null)}>
                  Сбросить выбор
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={style.footer}>
        <Button
          variant="secondary"
          onClick={onHide}
          className={style.cancelBtn}
        >
          Отмена
        </Button>
        <CustomButton onClick={handleFormSubmit}>Сохранить</CustomButton>
      </Modal.Footer>
    </Modal>
  );
}
