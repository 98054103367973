import iconClose from "src/assets/icons/ic-Close.svg";
import iconExitButton from "src/assets/icons/ic-ExitButton.svg";
import iconEyeButton from "src/assets/icons/ic-EyeButton.svg";
import iconLogoMini from "src/assets/icons/ic-LogoMini.svg";
import iconMenuBurger from "src/assets/icons/ic-MenuBurger.svg";
import iconTrash from "src/assets/icons/ic-Trash.svg";
import iconUnion from "src/assets/icons/ic-Union.svg";
import iconUpdateTable from "src/assets/icons/ic-UpdateTable.svg";

export const icClose = iconClose;
export const icUnion = iconUnion;
export const icTrash = iconTrash;
export const icUpdateTable = iconUpdateTable;
export const icMenuBurger = iconMenuBurger;
export const icEyeButton = iconEyeButton;
export const icLogoMini = iconLogoMini;
export const icExitButton = iconExitButton;
