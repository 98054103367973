import Api from "src/shared/api/axiosWrapper";

const URL = "auth/token";

export const deleteAuthToken = () =>
  Api.delete({
    url: `${URL}`,
  });

export const getUsersMe = () =>
  Api.get({
    url: "/users/me",
  });

export const postAuth = (data) =>
  Api.post({
    url: `${URL}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data,
  });
