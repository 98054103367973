import { makeAutoObservable } from "mobx";

import {
  createPaymentForm,
  getPaymentForm,
  deletePaymentForm,
  updatePaymentForm,
  getPaymentFormActTypes,
} from "src/shared/api/paymentForms";

export const PaymentFormsStore = () => {
  const store = {
    forms: [],
    actTypes: [],
    isLoading: false,
    error: null,

    setForms(forms) {
      this.forms = forms;
    },

    setActTypes(actTypes) {
      this.actTypes = actTypes;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    async loadForms() {
      try {
        this.setIsLoading(true);
        const res = await getPaymentForm();
        this.setForms(res.data.data);
        this.setError(null);
      } catch (error) {
        this.setError("Ошибка при загрузке форм выплат");
      } finally {
        this.setIsLoading(false);
      }
    },

    async loadActTypes() {
      try {
        this.setIsLoading(true);
        const res = await getPaymentFormActTypes();
        this.setActTypes(res.data.types);
        this.setError(null);
      } catch (error) {
        this.setError("Ошибка при загрузке типов актов");
      } finally {
        this.setIsLoading(false);
      }
    },

    async addForm(data) {
      this.setIsLoading(true);
      try {
        const res = await createPaymentForm(data);
        this.setForms([...this.forms, res.data]);
        this.setError(null);
      } catch (error) {
        if (error.response.status === 400) {
          this.setError(
            error.response.data.detail || "Ошибка 400: некорректные данные",
          );
        } else if (error.response.status === 409) {
          this.setError(error.response.data.detail || "Ошибка: конфликт");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async updateForm(id, data) {
      this.setIsLoading(true);
      try {
        const res = await updatePaymentForm(id, data);
        if (res.data) {
          this.setForms(
            this.forms.map((form) => (form.id === id ? res.data : form)),
          );
          this.setError(null);
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.setError(
            error.response.data.detail || "Ошибка 400: некорректные данные",
          );
        } else if (error.response.status === 409) {
          this.setError(error.response.data.detail || "Ошибка: конфликт");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async deleteForm(id) {
      try {
        await deletePaymentForm(id);
        this.setForms(this.forms.filter((form) => form.id !== id));
      } catch (error) {
        this.setError("Ошибка при удалении формы выплат");
      }
    },
  };

  makeAutoObservable(store);
  return store;
};
