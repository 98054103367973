import { useNavigate } from "react-router-dom";

export function useDepartamentNavigation() {
  const navigate = useNavigate();

  function navigateToCreateDepartament() {
    navigate("/departament/createDepartament");
  }

  return { navigateToCreateDepartament };
}
