import Api from "src/shared/api/axiosWrapper";

const URL = "bets";

export const getBets = (params) =>
  Api.get({
    url: `${URL}`,
    params,
  });

export const createBet = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const createMonthBet = (data) =>
  Api.post({
    url: `${URL}/month`,
    data,
  });

export const updateBet = (id, data) =>
  Api.patch({
    url: `${URL}/${id}`,
    data,
  });

export const getBet = (id) =>
  Api.get({
    url: `${URL}/${id}`,
  });

export const deleteBet = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });
