import { useEffect } from "react";

import { useRootStore } from "src/shared/stores/initStore";

export const useSizePageDepartment = (
  initialPageSize = 100,
  resetPageSize = 20,
) => {
  const { departamentStore } = useRootStore();

  useEffect(() => {
    departamentStore.setPageSize(initialPageSize);
    departamentStore.loadDepartaments();
    return () => {
      departamentStore.setPageSize(resetPageSize);
      departamentStore.loadDepartaments();
    };
  }, [departamentStore, initialPageSize, resetPageSize]);
};
