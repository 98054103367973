import { useEffect } from "react";

import { observer } from "mobx-react";
import { Table } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import { CustomButton } from "src/shared";
import LoadBar from "src/shared/components/loaders/LoadBar";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./AllEmployeeAccruals.module.css";

export const AllEmployeeAccruals = observer(() => {
  const { allAccrualsStore, authStore } = useRootStore();
  const { employeeId } = useParams();
  const { employeeAccruals, isLoading } = allAccrualsStore;
  const userRole = authStore.userData?.profile?.role;
  const navigate = useNavigate();

  useEffect(() => {
    if (employeeId) {
      allAccrualsStore.loadEmployeeAccruals(employeeId);
    }
  }, [employeeId, allAccrualsStore]);

  if (!employeeAccruals) {
    return <div>Данные загружаются...</div>;
  }

  const {
    employee,
    bets_info = {},
    deal_charges_info = {},
    surcharges_info = {},
    salary = null,
    sum_with_tax,
    tax_sum,
    total_accrued,
  } = employeeAccruals;

  const bets = bets_info.bets || [];
  const deals = deal_charges_info.deal_charges || [];
  const surcharges = surcharges_info.surcharges || [];

  return (
    <div className={style.tablesContainer}>
      <div className={style.tableContainer}>
        <h2>Ставки</h2>
        {isLoading ? (
          <LoadBar />
        ) : (
          <Table bordered hover className={style.table}>
            <thead>
              <tr>
                <th className={style.rowBets}>
                  <span>Сотрудник</span>
                  <span>Должность</span>
                  <span>Кол-во ставок</span>
                  <span>Цена ставки</span>
                  <span>Итого сумма за ставки</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {bets.length === 0 ? (
                <tr>
                  <td colSpan="5">Данные не найдены</td>
                </tr>
              ) : (
                bets.map((bet) => (
                  <tr key={bet.id}>
                    <td className={style.rowBets}>
                      <span>{employee.fullname}</span>
                      <span>{employee.job_title || "-"}</span>
                      <span>{bet.bets_num}</span>
                      <span>{bet.bets_price.toLocaleString()}</span>
                      <span>{bet.amount.toLocaleString()}</span>
                    </td>
                  </tr>
                ))
              )}
              {bets.length > 0 && (
                <tr>
                  <td className={style.rowBetsSum}>
                    <strong>Итого:</strong>
                    <strong> </strong>
                    <strong> </strong>
                    <strong> </strong>
                    <strong>
                      {bets_info.total_amount_bets.toLocaleString()}
                    </strong>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </div>

      <div className={style.tableContainer}>
        <h2>Сделки</h2>
        {isLoading ? (
          <LoadBar />
        ) : (
          <Table bordered hover className={style.table}>
            <thead>
              <tr>
                <th className={style.rowDeals}>
                  <span>Дата</span>
                  <span>ФИО</span>
                  <span>Ед. измерения сделки</span>
                  <span>Цена сделки</span>
                  <span>Объём</span>
                  <span>Сумма</span>
                  <span className={style.tableComment}>Примечание</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {deals.length === 0 ? (
                <tr>
                  <td colSpan="6">Данные не найдены</td>
                </tr>
              ) : (
                deals.map((deal) => (
                  <tr key={deal.id}>
                    <td className={style.rowDeals}>
                      <span>
                        {new Date(deal.accrual_date).toLocaleDateString()}
                      </span>
                      <span>{employee.fullname}</span>
                      <span>{deal.unit}</span>
                      <span>{deal.cost.toLocaleString()}</span>
                      <span>{deal.volume}</span>
                      <span>{deal.amount}</span>
                      <span className={style.tableComment}>{deal.comment}</span>
                    </td>
                  </tr>
                ))
              )}
              {deals.length > 0 && (
                <tr>
                  <td className={style.rowDealsSum}>
                    <strong>Итого:</strong>
                    <strong> </strong>
                    <strong> </strong>
                    <strong> </strong>
                    <strong> </strong>
                    <strong>
                      {deal_charges_info.total_amount_deal_charges.toLocaleString()}
                    </strong>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </div>

      <div className={style.tableContainer}>
        <h2>Доплаты</h2>
        {isLoading ? (
          <LoadBar />
        ) : (
          <Table bordered hover className={style.table}>
            <thead>
              <tr>
                <th className={style.rowSurch}>
                  <span>Дата</span>
                  <span>ФИО</span>
                  <span>Тип доплаты</span>
                  <span>Сумма доплаты</span>
                  <span className={style.tableComment}>Примечание</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {surcharges.length === 0 ? (
                <tr>
                  <td colSpan="5">Данные не найдены</td>
                </tr>
              ) : (
                surcharges.map((surcharge) => (
                  <tr key={surcharge.id}>
                    <td className={style.rowSurch}>
                      <span>
                        {new Date(surcharge.accrual_date).toLocaleDateString()}
                      </span>
                      <span>{employee.fullname}</span>
                      <span>{surcharge.surcharge_type}</span>
                      <span>{surcharge.amount.toLocaleString()}</span>
                      <span className={style.tableComment}>
                        {surcharge.comment}
                      </span>
                    </td>
                  </tr>
                ))
              )}
              {surcharges.length > 0 && (
                <tr>
                  <td className={style.rowSurchSum}>
                    <strong>Итого:</strong>
                    <strong> </strong>
                    <strong> </strong>
                    <strong>
                      {surcharges_info.total_amount_surcharges.toLocaleString()}
                    </strong>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </div>

      <div>
        <h2>Оклады</h2>
        {isLoading ? (
          <LoadBar />
        ) : (
          <Table bordered hover className={style.table}>
            <thead>
              <tr>
                <th className={style.rowSalary}>
                  <span>Дата</span>
                  <span>Сумма</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {salary ? (
                <tr>
                  <td className={style.rowSalary}>
                    <span>
                      {new Date(salary.accrual_date).toLocaleDateString()}
                    </span>
                    <span>{salary.amount.toLocaleString()}</span>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="2">Данные не найдены</td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </div>
      {userRole === "accountant" && (
        <div>
          <h2>Итого</h2>
          {isLoading ? (
            <LoadBar />
          ) : (
            <Table bordered hover className={style.table}>
              <thead>
                <tr>
                  <th className={style.rowTotal}>
                    <span>Итого</span>
                    <span>Налоги</span>
                    <span>Итого с налогом</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={style.rowTotal}>
                    <span>{total_accrued.toLocaleString() || "-"}</span>
                    <span>{tax_sum.toLocaleString() || "-"}</span>
                    <span>{sum_with_tax.toLocaleString() || "-"}</span>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </div>
      )}
      <CustomButton onClick={() => navigate(-1)}>Вернуться назад</CustomButton>
    </div>
  );
});
