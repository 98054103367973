import { observer } from "mobx-react";
import { NavLink } from "react-router-dom";

import styles from "src/shared/components/Sidebar/index.module.css";
import Spinner from "src/shared/components/loaders/Spinner";
import { useRootStore } from "src/shared/stores/initStore";

export const DepHeadMenu = observer(({ onClose }) => {
  const { authStore, sidebarStore } = useRootStore();
  const userRole = authStore.userData?.profile?.role;

  const handleNavlinkClick = () => {
    onClose();
  };

  return (
    <div className={styles.containerForMenu}>
      {userRole === undefined ? (
        <Spinner />
      ) : (
        userRole === "department_head" && (
          <>
            <a
              className={`${styles.dropdownButton} ${sidebarStore.dropdownReports ? styles.dropdownButtonAction : ""}`}
              href="#!"
              onClick={() => sidebarStore.toggleDropdownReports()}
            >
              Начисления
            </a>
            {sidebarStore.dropdownReports && (
              <ul className={styles.list}>
                <li>
                  <NavLink
                    onClick={handleNavlinkClick}
                    to="accruals/extraPayments"
                  >
                    Доплаты
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={handleNavlinkClick} to="accruals/deals">
                    Сделки
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={handleNavlinkClick} to="accruals/bets">
                    Ставки
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleNavlinkClick}
                    to="accruals/allAccrulas"
                  >
                    Все начисления
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={handleNavlinkClick} to="accruals/reports">
                    Отчёты
                  </NavLink>
                </li>
              </ul>
            )}

            <NavLink
              onClick={handleNavlinkClick}
              to="vacations"
              className={styles.dropdownButton}
            >
              Отпуска
            </NavLink>

            <NavLink
              onClick={handleNavlinkClick}
              to="employees"
              className={styles.dropdownButton}
            >
              Сотрудники
            </NavLink>
          </>
        )
      )}
    </div>
  );
});
