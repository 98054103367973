import styles from "src/shared/components/customButton/index.module.css";

function CustomButton({
  onClick,
  children = "",
  disabled = false,
  style = {},
  type = "button"
}) {
  const buttonClass = `${styles.customButton}`.trim();

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
      type={type === "submit" ? "submit" : "button"}
      style={style}
    >
      {children}
    </button>
  );
}

export default CustomButton;
