import React from "react";

import moment from "moment";
import { Form } from "react-bootstrap";

import style from "./ReusableBootstrapMonthPicker.module.css";

function ReusableBootstrapMonthPicker(props) {
  const { selectedMonth, onChange } = props;

  const handleMonthChange = (e) => {
    if (e?.target?.value) {
      const selectedDate = new Date(`${e.target.value}-01`);
      if (moment(selectedDate).isValid()) {
        onChange(selectedDate);
      }
    }
  };

  return (
    <Form.Control
      type="month"
      value={selectedMonth ? moment(selectedMonth).format("YYYY-MM") : ""}
      onChange={handleMonthChange}
      onClick={(e) => e.target.showPicker()}
      className={style.datePicker}
    />
  );
}

export default ReusableBootstrapMonthPicker;
