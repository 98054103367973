import { Form } from "react-bootstrap";

import styles from "../styles.module.css";

export default function FormGroup(props) {
  const {
    controlId,
    label,
    as,
    type,
    name,
    required,
    value,
    onChange,
    placeholder,
    className,
    min,
    max,
    step,
  } = props;

  const formGroupClass = `${styles.formControlFontSize} ${className || ""}`;

  return (
    <Form.Group controlId={controlId} className={formGroupClass}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as={as}
        type={type}
        name={name}
        required={required}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        min={min}
        max={max}
        step={step}
      />
    </Form.Group>
  );
}
