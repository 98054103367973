import { useState, useEffect } from "react";

import { observer } from "mobx-react";
import { Toast } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { icEyeButton, icLogoMini } from "src/assets/icons/icons";
import styles from "src/pages/auth/index.module.css";
import Loader from "src/shared/components/loaders/Loader";
import { useRootStore } from "src/shared/stores/initStore";

export const Auth = observer(() => {
  const { authStore } = useRootStore();

  const [data, setData] = useState({ username: "", password: "" });
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (authStore.loginError) {
      setError(authStore.loginError);
      setShowToast(true);
    }
  }, [authStore.loginError]);

  useEffect(() => {
    document.title = "Авторизация - Physical";
  }, []);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (data.username.trim() === "" || data.password.trim() === "") {
      setError("Заполните оба поля ввода");
      setShowToast(true);

      return;
    }
    authStore.postLogin(data);
  };

  const handleInputChange = (text, name) => {
    setData({ ...data, [name]: text.target.value });
  };

  const handleCloseToast = () => setShowToast(false);

  const showPassword = () => setShowPass(true);
  const hidePassword = () => setShowPass(false);

  return (
    <div className={styles.container}>
      <Toast
        className={styles.toast}
        bg="Warning"
        show={showToast}
        onClose={handleCloseToast}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">Ошибка авторизации</strong>
        </Toast.Header>
        <Toast.Body className={styles.error}>{error}</Toast.Body>
      </Toast>

      <Form className={styles.form} onSubmit={handleOnSubmit}>
        <img src={icLogoMini} className={styles.logo} alt="logo" />
        <h3 className={styles.title}>Авторизация</h3>
        <Form.Group className="mb-4 mt-3" controlId="formBasicEmail">
          <Form.Label className={styles.label}>Логин</Form.Label>
          <Form.Control
            className={styles.input}
            value={data.username}
            type="text"
            placeholder="Введите логин"
            onChange={(e) => handleInputChange(e, "username")}
          />
        </Form.Group>

        <Form.Group className={styles.formGroup} controlId="formBasicPassword">
          <Form.Label className={styles.label}>Пароль</Form.Label>
          <Form.Control
            className={styles.input}
            value={data.password}
            type={showPass ? "text" : "password"}
            placeholder="Введите пароль"
            onChange={(e) => handleInputChange(e, "password")}
            style={{ paddingRight: "25px" }}
          />
          <button
            onTouchStart={showPassword}
            onTouchEnd={hidePassword}
            onMouseDown={showPassword}
            onMouseUp={hidePassword}
            onMouseLeave={hidePassword}
            type="button"
            className={styles.showPasswordButton}
          >
            <img src={icEyeButton} alt="eyeButton" />
          </button>
        </Form.Group>

        <button
          type="submit"
          className={styles.submitButton}
          disabled={authStore.isLoadingPostLogin}
        >
          {authStore.isLoadingPostLogin ? <Loader /> : "Вход"}
        </button>
      </Form>
    </div>
  );
});
