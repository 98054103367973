import Api from "src/shared/api/axiosWrapper";

const URL = "users";

export const getUsers = (params) =>
  Api.get({
    url: `${URL}`,
    params,
  });

export const createUser = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const updateUser = (id, data) =>
  Api.patch({
    url: `${URL}/${id}`,
    data,
  });

export const deleteUser = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });
