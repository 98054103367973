import { useEffect, useState } from "react";

import { Modal, Button } from "react-bootstrap";

import style from "./ErrorModal.module.css";

function ErrorModal({ show, onHide, errorMessage }) {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    let timer;
    if (show) {
      setCountdown(5);

      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            onHide();
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [show, onHide]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <p className={style.textAlign}>Ошибка</p>
      </Modal.Header>
      <Modal.Body className={style.center}>{errorMessage}</Modal.Body>
      <Modal.Footer className={style.center}>
        <Button variant="danger" onClick={onHide}>
          {`Закрыть (${countdown})`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ErrorModal;
