export const thDealsArray = [
  "Дата",
  "ФИО",
  "Отдел",
  "Ед. измерения",
  "Цена сделки",
  "Объем сделки",
  "Сумма сделки",
  "Примечание",
  " ",
];
