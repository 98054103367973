import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Table } from "react-bootstrap";

import { icTrash } from "src/assets/icons/icons";
import style from "src/shared/components/Tables/employmentForms/index.module.css";
import CustomButton from "src/shared/components/customButton/CustomButton";
import LoadBar from "src/shared/components/loaders/LoadBar";
import PaginationPayment from "src/shared/components/paginations/PaginationComponent";
import { useRootStore } from "src/shared/stores/initStore";
import EmploymentAddForm from "src/shared/ui/modals/employmentForms/EmploymentAddForm";
import EmploymentUpdateForm from "src/shared/ui/modals/employmentForms/EmploymentUpdateForm";
import ConfirmationModal from "src/shared/ui/modals/modalConfirm/ConfirmationModal";
import { PageWrapper } from "src/shared/ui/pageWrapper";

export const EmploymentForms = observer(() => {
  const { employmentFormsStore } = useRootStore();
  // ДОБАВЛЕНИЕ
  const [showAddFormModal, setShowAddFormModal] = useState(false);
  // УДАЛЕНИЕ
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentFormId, setCurrentFormId] = useState(null);
  const [currentFormTitle, setCurrentFormTitle] = useState("");
  // РЕДАКТИРОВАНИЕ
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFormId, setEditFormId] = useState(null);
  const [editFormTitle, setEditFormTitle] = useState("");
  const [editTaxPercentage, setEditTaxPercentage] = useState(0);
  // ПАГИНАЦИЯ
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employmentFormsStore.employments.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  // УДАЛЕНИЕ
  const handleDelete = (e, id, title) => {
    e.stopPropagation();
    setCurrentFormId(id);
    setCurrentFormTitle(title);
    setShowDeleteModal(true);
  };
  const confirmDelete = () => {
    employmentFormsStore.deleteEmployment(currentFormId);
    setShowDeleteModal(false);
  };

  // ДОБАВЛЕНИЕ
  const handleCloseModal = () => setShowAddFormModal(false);
  const handleAddNewEmployment = () => {
    employmentFormsStore.clearError();
    setShowAddFormModal(true);
  };
  const handleSave = (title, taxPercentage) => {
    const titleTrimmed = title.trim();
    const tax = parseFloat(taxPercentage);

    employmentFormsStore
      .addEmployment({ title: titleTrimmed, taxPercentage: tax })
      .then(() => {
        if (!employmentFormsStore.error) {
          setShowAddFormModal(false);
          employmentFormsStore.clearError();
          employmentFormsStore.loadEmployments();
        }
      });
  };

  // РЕДАКТИРОВАНИЕ
  const handleEdit = (id, title, taxPercentage) => {
    setEditFormId(id);
    setEditFormTitle(title);
    setEditTaxPercentage(taxPercentage);
    employmentFormsStore.clearError();
    setShowEditModal(true);
  };
  const handleUpdate = (id, title, taxPercentage) => {
    const titleTrimmed = title.trim();
    const tax = parseFloat(taxPercentage);
    employmentFormsStore
      .updateEmployment(id, { title: titleTrimmed, tax_percentage: tax })
      .then(() => {
        if (!employmentFormsStore.error) {
          setShowEditModal(false);
          employmentFormsStore.loadEmployments();
        }
      });
  };

  useEffect(() => {
    employmentFormsStore.loadEmployments();
  }, [employmentFormsStore]);

  useEffect(() => {
    document.title = "Формы ТУ - Physical";
  }, []);

  return (
    <PageWrapper>
      <h1 className={style.headerTitle}>Формы ТУ</h1>

      <CustomButton
        onClick={handleAddNewEmployment}
        disabled={false}
        style={{ margin: "20px 0" }}
      >
        Новая форма ТУ
      </CustomButton>

      <div className={style.tableScrollWrapper}>
        <Table striped bordered hover className={style.tableScrollWidth}>
          <thead>
            <tr>
              <th className={style.tableHeadContainer}>
                <span className={style.tableHeadName}>Название</span>
                <span className={style.taxHeadPercentage}>% налога</span>
                <span className={style.buttonBackground}> </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {employmentFormsStore.isLoading ? (
              <tr>
                <td colSpan="1" style={{ textAlign: "center" }}>
                  <LoadBar />
                </td>
              </tr>
            ) : (
              currentItems.map((employ) => (
                <tr key={employ.id}>
                  <td
                    className={style.tableTitle}
                    onClick={() =>
                      handleEdit(employ.id, employ.title, employ.tax_percentage)
                    }
                  >
                    <span className={style.titleText}>{employ.title}</span>
                    <span className={style.taxPercentage}>
                      {employ.tax_percentage.toFixed(2)}%
                    </span>
                    <button
                      type="button"
                      className={style.buttonBackground}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(e, employ.id, employ.title);
                      }}
                    >
                      <img
                        className={style.iconTrash}
                        src={icTrash}
                        alt="delete_item"
                      />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>

      <EmploymentAddForm
        show={showAddFormModal}
        onHide={handleCloseModal}
        handleSave={handleSave}
        error={employmentFormsStore.error}
      />

      <EmploymentUpdateForm
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        initialTitle={editFormTitle}
        initialTaxPercentage={editTaxPercentage}
        onUpdate={handleUpdate}
        formId={editFormId}
        error={employmentFormsStore.error}
      />

      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
        title="Подтверждение удаления"
      >
        Вы действительно хотите удалить {currentFormTitle}
      </ConfirmationModal>

      {employmentFormsStore.isLoading ? null : (
        <PaginationPayment
          itemsPerPage={itemsPerPage}
          totalItems={employmentFormsStore.employments.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </PageWrapper>
  );
});
