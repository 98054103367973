import { useState, useEffect } from "react";

import { observer } from "mobx-react";
import moment from 'moment';
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Select from "react-select";

import { CustomButton } from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./DealsAddEditModal.module.css";

export const DealsAddEditModal = observer(({ show, handleClose, deal }) => {
  const { accrualsDealsStore, departamentStore, employeesStore } =
    useRootStore();

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [dealDate, setDealDate] = useState("");
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [dealPrice, setDealPrice] = useState("");
  const [dealVolume, setDealVolume] = useState("");
  const [dealTotal, setDealTotal] = useState("");
  const [dealNote, setDealNote] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formatNumber = (value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const removeSpaces = (value) => String(value).replace(/\s/g, "");

  const resetForm = () => {
    const today = moment().format("YYYY-MM-DD");
    setSelectedDepartment("");
    setSelectedEmployee("");
    setDealDate(today);
    setSelectedUnit([]);
    setDealPrice("");
    setDealVolume("");
    setDealNote("");
    setFormSubmitted(false);
    setErrorMessage("");
  };

  useEffect(() => {
    if (deal) {
      setSelectedDepartment(deal.department.id);
      setSelectedEmployee(deal.employee.id);
      setDealDate(deal.deal_charge_date);
      setSelectedUnit(deal.unit ? [{ label: deal.unit, id: deal.unit }] : []);
      setDealPrice(deal.cost);
      setDealVolume(deal.volume);
      setDealNote(deal.comment);
    } else {
      resetForm();
    }
  }, [deal]);

  useEffect(() => {
    const price = parseFloat(removeSpaces(dealPrice));
    const volume = parseFloat(removeSpaces(dealVolume));
    if (!Number.isNaN(price) && !Number.isNaN(volume)) {
      setDealTotal(formatNumber((price * volume).toFixed(2)));
    } else {
      setDealTotal("");
    }
  }, [dealPrice, dealVolume]);

  useEffect(() => {
    departamentStore.loadDepartaments();
    employeesStore.loadEmployees();
    accrualsDealsStore.loadUnitsOfMeasurement();
  }, [departamentStore, employeesStore, accrualsDealsStore]);

  useEffect(() => {
    if (selectedDepartment) {
      const employees =
        employeesStore.getEmployeesByDepartment(selectedDepartment);
      setFilteredEmployees(employees);
    } else {
      setFilteredEmployees([]);
    }
  }, [selectedDepartment, employeesStore]);

  useEffect(() => {
    if (show) {
      setErrorMessage("");
    }
  }, [show]);

  const handleSave = async () => {
    setFormSubmitted(true);

    if (!selectedDepartment) {
      setErrorMessage("Пожалуйста выберите отдел.");
      return;
    }
    if (!selectedEmployee) {
      setErrorMessage("Пожалуйста выберите сотрудника.");
      return;
    }
    if (!dealDate) {
      setErrorMessage("Пожалуйста выберите дату сделки.");
      return;
    }
    if (!selectedUnit.length) {
      setErrorMessage("Пожалуйста выберите единицу измерения.");
      return;
    }
    if (!dealPrice || parseFloat(removeSpaces(dealPrice)) <= 0) {
      setErrorMessage("Пожалуйста введите корректную цену сделки.");
      return;
    }
    if (!dealVolume || parseFloat(removeSpaces(dealVolume)) <= 0) {
      setErrorMessage("Пожалуйста введите корректный объем сделки.");
      return;
    }

    const dealData = {
      department_id: selectedDepartment,
      employee_id: selectedEmployee,
      deal_charge_date: dealDate,
      unit: selectedUnit.length ? selectedUnit[0].label : "",
      cost: parseFloat(removeSpaces(dealPrice)),
      volume: parseFloat(removeSpaces(dealVolume)),
      amount: parseFloat(removeSpaces(dealTotal)),
      comment: dealNote,
    };

    try {
      if (deal) {
        await accrualsDealsStore.updateDeal(deal.id, dealData);
      } else {
        await accrualsDealsStore.addDeal(dealData);
      }
      resetForm();
      handleClose();
      const year = new Date().getFullYear();
      const month = (
        dealDate ? new Date(dealDate).getMonth() + 1 : new Date().getMonth() + 1
      )
        .toString()
        .padStart(2, "0");
      accrualsDealsStore.loadDeals(`${year}-${month}-01`);
    } catch (error) {
      setErrorMessage("Произошла ошибка при сохранении сделки.");
    }
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption.value);
    setSelectedEmployee("");
  };

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption.value);
  };

  const handleUnitChange = (selected) => {
    setSelectedUnit(selected);
  };

  const handleNumberInputChange = (setter) => (e) => {
    const value = e.target.value.replace(/\s/g, "");
    if (/^\d*$/.test(value)) {
      setter(formatNumber(value));
    }
  };

  const departments = departamentStore.departaments.map((dept) => ({
    value: dept.id,
    label: dept.title,
  }));

  const employees = filteredEmployees.map((emp) => ({
    value: emp.id,
    label: emp.fullname,
  }));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {deal ? "Редактирование сделки" : "Создание сделки"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage("")}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        <Form>
          <Form.Group controlId="formDepartment">
            <Form.Label>Отдел</Form.Label>
            <Select maxMenuHeight={200}
              value={departments.find(
                (dept) => dept.value === selectedDepartment,
              )}
              onChange={handleDepartmentChange}
              options={departments}
              placeholder="Выберите отдел"
            />
          </Form.Group>
          <Form.Group controlId="formEmployee" className={style.mt_10}>
            <Form.Label>Сотрудник</Form.Label>
            <Select maxMenuHeight={200}
              value={employees.find((emp) => emp.value === selectedEmployee)}
              onChange={handleEmployeeChange}
              options={employees}
              placeholder="Выберите сотрудника"
            />
          </Form.Group>
          <Form.Group controlId="formDate">
            <Form.Label className={style.mt_10}>Дата сделки</Form.Label>
            <Form.Control
              type="date"
              value={dealDate}
              onChange={(e) => setDealDate(e.target.value)}
              onClick={(e) => e.target.showPicker()}
            />
          </Form.Group>
          <Form.Group controlId="formUnit">
            <Form.Label className={style.mt_10}>Ед. измерения</Form.Label>
            <Typeahead
              id="unitOfMeasurement"
              labelKey="label"
              onChange={handleUnitChange}
              options={accrualsDealsStore.unitsOfMeasurement.map((unit) => ({
                id: unit,
                label: unit,
              }))}
              placeholder="Выберите или введите единицу измерения"
              selected={selectedUnit}
              allowNew
            />
          </Form.Group>
          <Form.Group controlId="formPrice">
            <Form.Label className={style.mt_10}>Цена сделки</Form.Label>
            <Form.Control
              type="text"
              value={dealPrice}
              onChange={handleNumberInputChange(setDealPrice)}
              placeholder="Введите цену сделки"
            />
          </Form.Group>
          <Form.Group controlId="formVolume">
            <Form.Label className={style.mt_10}>Объем сделки</Form.Label>
            <Form.Control
              type="text"
              value={dealVolume}
              onChange={handleNumberInputChange(setDealVolume)}
              placeholder="Введите объем сделки"
            />
          </Form.Group>
          <Form.Group controlId="formTotal">
            <Form.Label className={style.mt_10}>Сумма сделки</Form.Label>
            <Form.Control type="text" value={dealTotal} readOnly />
          </Form.Group>
          <Form.Group controlId="formNote">
            <Form.Label className={style.mt_10}>Примечание</Form.Label>
            <Form.Control
              as="textarea"
              value={dealNote}
              onChange={(e) => setDealNote(e.target.value)}
              placeholder="Введите примечание"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={style.footer}>
        <Button variant="secondary" onClick={handleClose}>
          Закрыть
        </Button>
        <CustomButton onClick={handleSave}>Сохранить</CustomButton>
      </Modal.Footer>
    </Modal>
  );
});
