import { Form, Dropdown } from "react-bootstrap";

import styles from "../styles.module.css";

export default function FormDropdown(props) {
  const {
    controlId,
    label,
    options,
    selectedOption,
    onSelect,
    className,
    placeholder,
  } = props;

  const formGroupClass = `${styles.formControlFontSize} ${className || ""}`;

  return (
    <Form.Group controlId={controlId} className={formGroupClass}>
      <Form.Label>{label}</Form.Label>
      <Dropdown onSelect={(e) => onSelect(options.find((o) => o.id === e))}>
        <Dropdown.Toggle className={styles.dropdownContainer}>
          {selectedOption ? selectedOption.title : placeholder}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "scroll" }}>
          {options.map((option) => (
            <Dropdown.Item key={option.id} eventKey={option.id}>
              {option.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
}
