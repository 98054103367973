import { saveAs } from "file-saver";

import Api from "src/shared/api/axiosWrapper";

const URL = "reports";

export const getReports = (params) =>
  Api.get({
    url: `${URL}`,
    params,
  });

export const createReport = (data) =>
  Api.post({
    url: `${URL}`,
    data,
  });

export const updateReport = (id, data) =>
  Api.patch({
    url: `${URL}/${id}`,
    data,
  });

export const deleteReport = (id) =>
  Api.delete({
    url: `${URL}/${id}`,
  });

export const getReportStatuses = () =>
  Api.get({
    url: `${URL}/statuses`,
  });

export const downloadPayroll = async (params) => {
  try {
    const response = await Api.get({
      url: `/payroll`,
      params,
      responseType: "blob",
    });

    if (response.status === 200) {
      const contentType = response.headers["content-type"];
      const contentDisposition = response.headers["content-disposition"];

      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/"/g, "")
        : "report.zip";

      const blob = new Blob([response.data], { type: contentType });

      saveAs(blob, filename);
    }
  } catch (error) {
    throw new Error("Ошибка при скачивании файла payroll");
  }
};
